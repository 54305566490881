<template>
  <div class="comments">
    <div class="card h-260">
      <div class="card-body pl-4 pr-4" v-if="auth.user.user.core_role_id != 7">
        <h4 class="card-title text-center">
          Historial de Compras
        </h4>

        <table class="table table-hover">
          <thead>
          <tr>
            <th>Fecha</th>
            <th>Valor</th>
            <th>Acciones</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in data.transaction_lists" v-bind:key="item">
            <td>{{ item.created_at }}</td>
            <td>{{ FormatCurrency(item.amount) }}</td>
            <td>
              <a v-if="item.invoice == null" href="#" v-b-toggle="'90'" v-on:click="fileUpload(item.id)">Adjuntar Factura</a>
              <a v-else href="#" v-on:click="viewInvoice(item.invoice)">Ver Factura</a>
            </td>
          </tr>
          </tbody>
        </table>

        <div>
          <b-collapse accordion="accordion" id="90" class="mt-2 pt-2 pb-2">
            <div class="">
              <b-form @submit="uploadFile" enctype="multipart/form-data" >
                <b-form-group
                    id="input-group-1"
                    label="Adjuntar Factura:"
                    label-for="input-1"
                    description="Selecciona el archivo de la factura de compra"
                >
                  <b-form-file
                      v-model="invoice"
                      :state="Boolean(invoice)"
                      placeholder="Selecciona un archivo o arrastralo aqui..."
                      drop-placeholder="Arrastrar archivo aqui..."
                      browse-text="Seleccionar"
                  ></b-form-file>

                </b-form-group>

                <b-button class="pull-right" type="submit" variant="primary">Guardar</b-button>

              </b-form>

            </div>
          </b-collapse>
        </div>

      </div>
    </div>

    <b-modal
        id="modalInvoice"
        ref="modal_invoice" centered
        hide-footer
        hide-header
        size="natura">
      <div class="form-group">
        <div class="natura-title">
          <div class="natura-title-text">
            Factura de Compra
          </div>
        </div>

        <div v-if="invoiceImage" style="padding-top: 10px;">
          <object v-if="invoiceImage.split('.').pop().includes('pdf')"
                  class="bio-pdf-viewer"
                  :data="invoiceImage+'#view=FitH'"
                  type="application/pdf">
            <embed :src="invoiceImage+'#view=FitH'" type="application/pdf" />
          </object>
          <img
              v-else
              class="img-profile img-fluid mx-auto d-block"
              :src="invoiceImage"/>
        </div>

<!--        <div v-if="invoiceImage" class="">
          <img :src="invoiceImage"
               class="img-profile img-fluid mx-auto d-block" style="width: 50%;"/>
        </div>-->
      </div>

    </b-modal>

  </div>

</template>

<script>
import {mapState, mapActions} from 'vuex';
import moment from 'moment'
import {constants} from "../../../helpers";
import {Api} from "../../../helpers/api";
import auth from "../../../helpers/auth";

export default {
  name: "TransactionsHistory",
  props: {
    data: {type: Object, _default: []}
  },
  computed: {
    ...mapState('list_requests', {
      creditDetails: state => state.itemDetail,
      credit_id: state => state.credit_id
    })
  },
  data: () => {
    return {
      auth: auth,
      invoice: null,
      transaction_id: 0,
      invoiceImage: ''
    }
  },
  methods: {
    ...mapActions('list_requests', ['nextPage', 'loadRequests', 'loadDetail', 'setDetail', 'addComment']),
    format(date) {
      return moment(date).format('YYYY/MM/DD hh:ss a')
    },
    fileUpload(transactionId) {
      this.invoice = null;
      this.transaction_id = transactionId;
    },
    uploadFile(event) {
      event.preventDefault();

      if(this.invoice != null) {
        var formData1 = new FormData();
        formData1.append('transaction_id', this.transaction_id);
        formData1.append('invoice', this.invoice);

        this.$snotify.confirm('¿Esta seguro de adjuntar esta factura?', '', {
          buttons: [
            {
              text: 'NO', action: (toast) => {
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'SI', action: (toast) => {
                this.$snotify.remove(toast.id);
                this.$snotify.async('Enviando factura', 'Adjuntando archivo', () => new Promise((resolve, reject) => {

                  var formData = new FormData();
                  formData.append('transaction_id', this.transaction_id);
                  formData.append('invoice', this.invoice);
                  //console.log(formData, this.invoice, this.transaction_id);

                  Api.postFile(constants.endPoinds.saveInvoiceTransaction, formData, true).then(() => {

                    //console.log(response.data, response.data.data, response.data['data'], this.data.transaction_lists);
                    //console.log('antes');
                    //console.log(response.data, this.data.transaction_lists);

                    resolve({
                      title: '¡Adjuntado!',
                      body: 'Factura adjuntada correctamente',
                      config: {
                        closeOnClick: true, timeout: 3000
                      }
                    });
                  }).catch((response) => {
                    //console.log(response);
                    reject({
                      title: '¡Error!',
                      body: 'Algo fallo, vuelve a intentarlo',
                      error: response,
                      config: {
                        closeOnClick: true, timeout: 3000
                      }
                    });
                  })
                }));
              }
            },
          ]
        });

      }

    },
    viewInvoice(invoice) {
      this.$refs.modal_invoice.show();
      this.invoiceImage = invoice;
    },
    FormatCurrency: function(value) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      });

      return formatter.format(value);
    },
  },
  destroyed() {
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent) => {
      if(bvEvent.type === 'hide') {
        //console.log('Modal is about to be hide', bvEvent, modalId);
        clearInterval(this.validateInterval);
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/app';

.comments {
  .card-body {
    padding: 1rem;
  }

  .card-title {
    border-bottom: 1px solid $gray-600;
    padding-bottom: 1rem;
  }

  .scroll {
    max-height: 150px;
    overflow-y: auto;
    @include scroll-bar($sidebar-scrollbar-bg);
  }

  textarea {
    min-height: 100px;
  }

  .h-260 {
    min-height: 260px;
  }
}

.increased {
  textarea {
    min-height: 243px !important;
  }
}

.bio-pdf-viewer{
  width: 100%;
  height: 450px;
}
</style>