<template>
  <div class="card profile">
    <div class="card-body">
      <h4 class="card-title text-center">
        Perfil
      </h4>
      <div class="row">
        <div class="col-sm-4 text-center">
          <!--          transform: rotate(90deg);-->
          <!-- CLASE PARA ROTAR LA IMAGEN -->
          <!--  v-bind:class="[data.device_rotate ? 'rotate-img' : '']" -->
          <img :src="data.profile_image"
               class="img-profile img-fluid"/>
        </div>
        <div class="col-sm-4 p-3">
          <h3>Nombres</h3>
          <p>{{ data.names }}</p>
          <h3>Fecha de nacimiento</h3>
          <p>{{ data.birthdate }}</p>
          <h3>E-mail</h3>
          <p>{{ data.email }}</p>
        </div>
        <div class="col-sm-4 p-3">
          <h3>Apellidos</h3>
          <p>{{ data.last_names }}</p>
          <h3>N° Documento</h3>
          <p>{{ data.document_id | number }}</p>
          <h3>Celular</h3>
          <p>{{ data.phone }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Profile",
  props: {
    data: {type: Object, default: null}
  },
}
</script>

<style lang="scss" scoped>
@import '../../../styles/app';

.profile {
  .card-body {
    padding: 1rem;
  }

  .card-title {
    border-bottom: 1px solid $gray-600;
    padding-bottom: 1rem;
  }

  .img-profile {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    height: 150px;
    width: 150px;
    object-fit: cover;
  }

  h3 {
    color: $warning;
    font-weight: 500;
    font-size: 1.15rem;
    margin: 0;
  }

  .rotate-img {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}
</style>