<template>
  <div class="row">
    <div class="col-lg-6">
      <div class="card document-validation">
        <div class="card-body">
          <h4 class="card-title text-center">
            Verificacion de documento frontal y reverso
          </h4>
          <div v-if="data.status">
            <div class="row">
              <div class="col-sm-6 p-3">
                <h3>Documento (Reverso)</h3>
                <p>{{ data.data.document }}</p>
                <h3>Nombre (Reverso)</h3>
                <p>{{ data.data.first_name }}</p>
                <h3>Segundo Nombre (Reverso)</h3>
                <p>{{ data.data.second_name }}</p>
                <h3>Apellido (Reverso)</h3>
                <p>{{ data.data.last_name }}</p>
                <h3>Segundo Apellido (Reverso)</h3>
                <p>{{ data.data.second_last_name }}</p>
              </div>
              <div class="col-sm-6 p-3">
                <h3>Documento (Frontal)</h3>
                <p>{{ coincidence(data.data.document_match) }}</p>
                <h3>Nombre (Frontal)</h3>
                <p>{{ coincidence(data.data.first_name_match) }}</p>
                <h3>Segundo Nombre (Frontal)</h3>
                <p>{{ coincidence(data.data.second_name_match) }}</p>
                <h3>Apellido (Frontal)</h3>
                <p>{{ coincidence(data.data.last_name_match) }}</p>
                <h3>Segundo Apellido (Frontal)</h3>
                <p>{{ coincidence(data.data.second_last_name_match) }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="text-center">
                  <button @click="verifyDocument(true, credit.credit_id)" class="btn btn-rounded-f btn-success">
                    <i class="la la-check-circle"></i>Coincide
                  </button>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="text-center">
                  <button @click="verifyDocument(false, credit.credit_id)" class="btn btn-rounded-f btn-danger">
                    <i class="la la-times-circle"></i>No Coincide
                  </button>
                  <b-button v-b-modal.datacredito_modal variant="primary">
                    <i class="la la-address-book"></i>Resultados Datacredito
                  </b-button>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="text-center">
            <h2>No hay datos para comparar</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="card document-validation">
        <div class="card-body">
          <h4 class="card-title text-center">
            Verificacion de documento <span>vs</span> rostro
          </h4>
          <div v-if="data.status">
            <div class="row">
              <div class="col-sm-6">
                <img :src="data.data.front_document_image"
                     class="img-document img-fluid"/>
              </div>
              <div class="col-sm-6">
                <img :src="data.data.face_image"
                     class="img-profile img-fluid"/>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-sm-6">
                <h3>Parentesco</h3>
                <p>{{ data.data.face_confidence }} %</p>
              </div>
              <div class="col-sm-6">
                <h3>Coincidencia</h3>
                <p>{{ coincidence(data.data.face_is_identical) }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <h3>Porcentaje de verificacion</h3>
                <p>{{ data.data.verification_percentage }} %</p>
              </div>
              <div class="col-sm-6">
                <h3>Documento Coincide al 100%</h3>
                <p>{{ coincidence(data.data.verification) }}</p>
              </div>
            </div>
          </div>
          <div v-else class="text-center">
            <h2>No hay datos para comparar</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <b-modal size="xl" id="datacredito_modal" class="modal-xl" ref="datacredito_modal"
               title="Informacion de Datacredito" modal-footer="false">
        <div class="datacredito-html" v-html="datacredito">
          {{ datacredito }}
        </div>

        <div slot="modal-footer" class="text-center">
          <b-button size="xl" variant="danger" @click="hideModal">cerrar</b-button>
        </div>
      </b-modal>
    </div>
  </div>

</template>

<script>
import {mapState} from 'vuex';
import {constants, Api} from "../../../helpers";
import auth from "../../../helpers/auth";
// import Snotify from 'vue-snotify'
import Vue from 'vue'


export default {
  name: "DocumentVerify",
  props: {
    data: {
      type: Array, default: null
    },
    credit: {
      type: Array, default: null
    },
    datacredito: {
      type: Array, default: null
    }
  },
  computed: {
    ...mapState('list_requests', {
      credit_id: state => state.credit_id
    })
  },
  data: () => {
    return {
      auth: auth,
    }
  },
  methods: {
    hideModal() {
      this.$refs.datacredito_modal.hide();
    },
    coincidence: (status) => {
      let response = 'No Coincide';
      if (status) {
        response = 'Coincide';
      }
      return response;
    },
    verifyDocument: (confirmation, credit_id) => {
      // let message = "Confirmacion del documento";
      let content = "Al confirmar que el documento no coincide el credito no se verificara con datacredito y quedara cancelado.";
      if (confirmation) {
        // message = "Confirmacion del documento";
        content = "Al confirmar que el documento coincide, se iniciara el proceso de validacion con datacredito.";
      }

      Vue.prototype.$snotify.confirm(content, '', {
        buttons: [{
          text: 'NO', action: (toast) => {
            Vue.prototype.$snotify.remove(toast.id)
          }
        }, {
          text: 'SI', action: (toast) => {
            Vue.prototype.$snotify.remove(toast.id);
            Vue.prototype.$snotify.async('Enviando cambio', 'Cambiando estado', () => new Promise((resolve, reject) => {
              Api.post(constants.endPoinds.verifyDocument, {
                credit_id: credit_id,
                confirmation: confirmation
              }, true).then((response) => {
                if (response.data.status) {
                  resolve({
                    title: 'Solicitud actualizada',
                    body: response.data.message,
                    config: {
                      closeOnClick: true, timeout: 10000
                    }
                  })
                } else {
                  reject({
                    title: 'Solicitud actualizada',
                    body: response.data.message,
                    config: {
                      closeOnClick: true, timeout: 10000
                    }
                  })
                }
                this.remove();
                this.loadBadges();
              })
            }));
          }
        }
        ]
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/app';

.document-validation {
  .card-body {
    padding: 1rem;
  }

  .card-title {
    border-bottom: 1px solid $gray-600;
    padding-bottom: 1rem;
  }

  .img-profile {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    height: 150px;
    width: 150px;
    object-fit: cover;
  }

  .img-document {
    height: 150px;
    object-fit: cover;
  }

  h3 {
    color: $warning;
    font-weight: 500;
    font-size: 1.15rem;
    margin: 0;
  }

  span {
    color: $warning;
    font-weight: 500;
    margin: 0;
  }

  .rotate-img {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }

}
</style>