export default {
  namespaced: true,
  state: {
    sidebarClose: true,
    sidebarStatic: false,
    sidebarActiveElement: null,
    graphicsActive: false,
    qrCodeActive: false,
    reportsActive: false
  },
  mutations: {
    toggleSidebarState(state) {
      const nextState = !state.sidebarStatic;

      localStorage.sidebarStatic = nextState;
      state.sidebarStatic = nextState;
      state.sidebarClose = !nextState;
    },
    changeSidebarActive(state, index) {
      state.sidebarActiveElement = index;
    },
    setGraphicsActive(state, value){
      state.graphicsActive = value;
    },
    setQrCodeActive(state, value){
      state.qrCodeActive = value;
    },
    setReportsActive(state, value){
      state.reportsActive = value;
    }
  },
  actions: {
    toggleSidebar({ commit }) {
      commit('toggleSidebarState');
    },
    changeSidebarActive({ commit }, index) {
      commit('changeSidebarActive', index);
    },
    changeGraphics({commit}, value){
      commit('setGraphicsActive', value)
    },
    changeQrCode({commit}, value){
      commit('setQrCodeActive', value)
    },
    changeReports({commit}, value){
      commit('setReportsActive', value)
    }
  },
};
