<template>
  <div class="login-page">
    <b-container class="col-xs-12 col-sm-8 col-md-6 col-lg-4 offset-lg-4 col-xl-4 offset-xl-4">
      <b-card>
        <div class="content">
          <form class="mt" @submit.prevent="login">
            <div class="logo">
              <img src="../../assets/biocredit-imagotipo-vertical.png">
            </div>
            <b-alert class="alert-sm" :variant="classAlert" :show="!!message && !isShowModal">
              {{message}}
            </b-alert>
            <div class="form-group">
              <input ref="username" id="username" name="username" placeholder="Usuario"
                     class="form-control form-control-lg"/>
            </div>
            <div class="form-group">
              <input ref="password" name="password" type="password" placeholder="Contraseña"
                     class="form-control form-control-lg"/>
              <div class="text-right mt-3">
                <a @click="showModal">¿Olvidaste tu contraseña?</a>
              </div>
            </div>
            <div class="form-group">
              <b-button type="submit" block variant="warning" size="lg" class="btn-rounded-f">Ingresar</b-button>
            </div>
          </form>
        </div>
      </b-card>
      <div class="text-center m-2">
        <a style="color: white !important;">¿Necesitas ayuda?</a>
      </div>
    </b-container>
    <b-modal size="sm" ref="modal" title="Recuperar contraseña" modal-footer="false">
      <div class="form-group">
        <label for="resetPass"></label>
        <input id="resetPass" ref="resetPass" type="email" class="form-control" placeholder="example@email.com">
      </div>
      <b-alert class="alert-sm" :variant="classAlert" :show="!!message">
        {{message}}
      </b-alert>
      <div slot="modal-footer" class="text-right">
        <b-button size="sm" variant="outline-warning" @click="hideModal">Cancelar</b-button>
        <b-button size="sm" variant="warning" @click="resetPassword" class="ml-1">Enviar</b-button>
      </div>
    </b-modal>
    <footer class="footer">
      Copyright &copy; <span>BioCredit</span> todos los derechos reservados.
    </footer>
  </div>
</template>

<script>
  import {Api, constants} from "../../helpers";
  import auth from "../../helpers/auth";

  export default {
    name: 'LoginPage',
    data() {
      return {
        message: null,
        classAlert: 'danger',
        isShowModal: false
      };
    },
    methods: {
      login() {
        const username = this.$refs.username.value;
        const password = this.$refs.password.value;

        if (username.length !== 0 && password.length !== 0) {
          auth.login(username, password)
            .then(() => {
              this.$router.push('/app');
            }).catch(err => {
            this.message = err.response.data.message;
          })
        }
      },
      resetPassword() {
        const username = this.$refs.resetPass.value;
        if (username.length !== 0) {
          Api.post(constants.endPoinds.resetPassword, {
            email: username
          }).then(result => {
            this.classAlert = 'success';
            this.message = result.data.message;
            setTimeout(() => {
              this.hideModal();
              this.message = '';
              this.isShowModal = false;
            }, 3000)
          }).catch(err => {
            this.classAlert = 'danger';
            this.message = err.response.data.message;
          })
        }


      },
      showModal() {
        this.$refs.modal.show();
        this.isShowModal = true;
      },
      hideModal() {
        this.$refs.modal.hide();
        this.isShowModal = false;
      }
    },
    created() {
      if (auth.isLogin()) {
        this.$router.push('/app');
      }
    },
  };
</script>

<style src="./Login.scss" lang="scss" scoped/>
