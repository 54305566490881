<template>
  <div>
    <div class="card credid-details">
      <div class="card-body">
        <h4 class="card-title text-center">
          Informacion del crédito
        </h4>
        <div class="row">
          <div class="col-lg-6">
            <div class="row p-3 pb-0">
              <div class="col-md-6">
                <h3>Línea de crédito</h3>
                <p>{{data.credit_line}}</p>
                <h3>Valor del crédito</h3>
                <p>{{data.total_amount}}</p>
                <h3>Cuota</h3>
                <p>{{data.fee_value}}</p>
                <h3>Nombre del asesor</h3>
                <p>{{data.adviser}}</p>
                <h3 v-if="data.show_sign_new_terms">¿Acepto nuevos terminos?</h3>
                <p v-if="data.show_sign_new_terms">{{data.sign_new_terms ? 'Si' : 'No'}}</p>
                <h3 v-if="data.credit_status_id == 4">Estado de la solicitud</h3>
                <p v-if="data.credit_status_id == 4">Solicitud en proceso, el usuario debe finalizar el proceso</p>
              </div>
              <div class="col-md-6">
                <h3>Plazo</h3>
                <p>{{data.fee_number}}</p>
                <h3>Fecha de solicitud</h3>
                <p>{{data.created_at}}</p>
                <h3>Interés</h3>
                <p>{{data.rate}}</p>
                <h3>Lugar de origen</h3>
                <p>{{data.origin}}</p>
              </div>
            </div>

            <div v-if="userCreditHistory.length > 0">
              <hr class="mt-0">
              <div class="row pl-3 pr-3">
                <div class="col-md-6" v-for="(history, index) in userCreditHistory" v-bind:key="index" v-if="creditData.credit_id != history.credit_id">
                  <h3>Fecha de Creación: {{history.credit_created_at}}</h3>
                  <p style="margin-bottom: 0px;">Id: {{history.credit_id}}</p>
                  <p style="margin-bottom: 0px;">Estado: {{history.name}}</p>
                  <p style="margin-bottom: 0px;">Monto: $ {{ Intl.NumberFormat().format(history.total_amount) }}</p>
                  <p>No. Cuotas: {{history.fee_number}}</p>
                </div>
              </div>
            </div>

          </div>
          <div class="col-lg-6">
            <div v-if="data.ubication.lat != null" style="height: 100%">
              <GmapMap
                  :center="{lat: parseFloat(data.ubication.lat), lng: parseFloat(data.ubication.lng)}"
                  :zoom="12"
                  :options="{
                   zoomControl: false,
                   mapTypeControl: false,
                   scaleControl: false,
                   streetViewControl: false,
                   rotateControl: false,
                   fullscreenControl: false,
                   disableDefaultUi: false,
                   draggable: false
                 }"
                  style="width: 100%; height: 100%"
              >
                <GmapMarker
                    :position="{lat: parseFloat(data.ubication.lat), lng: parseFloat(data.ubication.lng)}"
                    :title="data.origin"
                />
              </GmapMap>
            </div>
            <div v-else class="text-center">
              <div class="pt-5">
                <i class="fa fa-map-marker" style="font-size: 30px"></i>
                <h2>Sin ubicación</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {gmapApi} from 'vue2-google-maps'
  import auth from "@/helpers/auth";

  export default {
    name: "CreditDetails",
    props: {
      data: {type: Object, default: null},
      userCreditHistory: {type: Array, default: () => ([])},
      creditData: {type: Array, default: () => ([])},
    },
    data() {
      return {
        auth: auth
      }
    },
    computed: {
      google: gmapApi
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../styles/app';

  .credid-details {
    .card-body {
      padding: 1rem;
    }
    .card-title {
      border-bottom: 1px solid $gray-600;
      padding-bottom: 1rem;
    }
    h3 {
      color: $warning;
      font-weight: 500;
      font-size: 1.15rem;
      margin: 0;
    }
  }
</style>