import {Api, constants, serializer} from "../helpers";
import auth from "../helpers/auth";

export default {
  namespaced: true,
  state: {
    listRequests: [],

    page: 1,
    status: null,
    start_date: null,
    end_date: null,
    search: null,

    subListRequests: {
      today: [],
      yesterday: [],
      week: [],
      month: [],
      rest: []
    },

    badges: {
      incoming: 0,
      approved: 0,
      denied: 0,
      inProcess: 0,
      returned: 0,
      pendingNatura: 0,
      cancelled: 0,
      askedForCall: 0,
      approved50: 0,
      whatsapp: 0
    },

    isLoading: false,
    isLoadingEnd: false,
    lastPage: 0,

    isDetailLoading: false,

    credit_id: null,
    itemDetail: null,

    currentList: null,
    currentIndexList: null,
    requestError: false,
    goLogin: false
  },
  mutations: {
    setToList(state) {
      let today = new Date().setHours(0, 0, 0, 0);
      let yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0, 0, 0, 0);
      let thisWeek = new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() - 1))).setHours(0, 0, 0, 0);
      let thisMonth = new Date(new Date().setDate(new Date().getDate() - (new Date().getDate() - 1))).setHours(0, 0, 0, 0);
      state.subListRequests = {
        today: [],
        yesterday: [],
        week: [],
        month: [],
        rest: []
      };
      state.listRequests.map((item) => {
        let dateOfItem = new Date(item.created_at).getTime();
        if (dateOfItem > today) {
          state.subListRequests.today.push(item);
        } else if (dateOfItem > yesterday) {
          state.subListRequests.yesterday.push(item);
        } else if (dateOfItem > thisWeek) {
          state.subListRequests.week.push(item);
        } else if (dateOfItem > thisMonth) {
          state.subListRequests.month.push(item);
        } else {
          state.subListRequests.rest.push(item)
        }
      });
    },
    setStatus(state, value) {
      state.status = value;
    },
    setIsLoading(state, value) {
      state.isLoading = value;
    },
    setIsLoadingEnd(state, value) {
      state.isLoadingEnd = value;
    },
    setDataDetail(state, details) {
      state.itemDetail = details;
    },
    setDates(state, params) {
      state.start_date = params.start_date;
      state.end_date = params.end_date;
    },
    clearDateFilter(state) {
      state.start_date = null;
      state.end_date = null;
    },
    setCreditId(state, id) {
      state.credit_id = id;
    },
    setSearch(state, search) {
      state.search = search;
    },
    setLoading(state, bool) {
      state.isDetailLoading = bool;
    },
    setCurrentInList(state, params) {
      state.currenList = params.list;
      state.currentIndexList = params.index;
    },
    crearLists(state) {
      state.subListRequests = {
        today: [],
        yesterday: [],
        week: [],
        month: [],
        rest: []
      }
    },
    setRequestError(state) {
      state.requestError = !state.requestError;
    },
    goLogin(state) {
      state.goLogin = !state.goLogin;
    },
    removeItemInCurrentList(state) {
      state.subListRequests[state.currenList].splice(state.currentIndexList, 1);
      state.currentIndexList = null;
    },
    setBadges(state, data) {
      Object.keys(data).map(function (objectKey) {
        let value = data[objectKey];
        let keyBadge = Object.keys(constants.creditsStatus).find((key) => constants.creditsStatus[key] == objectKey);
        state.badges[keyBadge] = value;
      });
    }
  },
  actions: {
    loadRequests({commit, state}, params) {
      commit('setDataDetail', null);
      commit('setCreditId', null);
      commit('setIsLoadingEnd', false);
      commit('setIsLoading', true);
      commit('crearLists');
      if (params !== false) {
        if (params.status !== undefined) {
          commit('setStatus', params.status);
        } else {
          params['status'] = state.status
        }
        if (params.search !== undefined) {
          commit('setSearch', params.search);
        } else if (state.search) {
          params['search'] = state.search;
        }
        if (params.start_date !== undefined && params.end_date !== undefined) {
          commit('setDates', params);
        } else if (state.start_date != null && state.end_date != null) {
          params['start_date'] = state.start_date;
          params['end_date'] = state.end_date;
        }

      } else {
        params = {
          page: state.page,
          status: state.status
        };
        if (state.start_date != null && state.end_date != null) {
          params['start_date'] = state.start_date;
          params['end_date'] = state.end_date;
        }
      }

      Api.get(constants.endPoinds.listCredits + serializer.get(params), true)
         .then((result) => {
           console.log(result);
           state.listRequests = result.data.data;
           state.page = result.data.current_page;
           state.lastPage = result.data.last_page;
           state.isLoadingEnd = result.data.last_page === 1;
           commit('setToList');
           commit('setIsLoading', false);
           // console.log('Consulta Inicial: ', result);
         }).catch((error) => {
        if (error.request) {
          auth.logout();
          commit('goLogin');
        }
        //commit('setRequestError');
        commit('setIsLoading', false);
      });
    },
    nextPage({commit, state}) {
      let params = {
        status: state.status,
        page: state.page + 1
      };
      if (state.start_date != null && state.end_date != null) {
        params['start_date'] = state.start_date;
        params['end_date'] = state.end_date;
      }
      if (!state.isLoadingEnd) {
        commit('setIsLoading', true);
        Api.get(constants.endPoinds.listCredits + serializer.get(params), true)
           .then(result => {
             state.listRequests.push(...result.data.data);
             state.page = result.data.current_page;
             state.isLoadingEnd = (params.page === state.lastPage);
             commit('setToList');
             commit('setIsLoading', false);
           }).catch((error) => {
          if (error.request) {
            auth.logout();
            commit('goLogin');
          }
          //commit('setRequestError');7
          commit('setIsLoading', false);
        })
      }
    },
    loadDetail({commit}, params) {
      return new Promise(resolve => {
        let id = params.id;
        commit('setCurrentInList', params);
        commit('setDataDetail', null);
        commit('setLoading', true);
        Api.get(constants.endPoinds.creditDetails + `?credit_id=${id}`, true)
           .then(data => {
             // console.log(data.data.data);
             commit('setDataDetail', data.data.data);
             commit('setCreditId', id);
             commit('setLoading', false);
             resolve()
           }).catch(error => {
          if (error.request) {
            auth.logout();
            commit('goLogin');
          }
          commit('setLoading', false);
        });

      });
    },

    setDetail({commit}, data) {
      commit('setDataDetail', data.data.data);
      commit('setCreditId', data.data.data.credit.id);
    },

    clearDates({commit}) {
      commit('clearDateFilter')
    },

    clearSearch({commit}) {
      commit('setSearch', null);
    },

    removeCurrentInList({commit}) {
      commit('setDataDetail', null);
      commit('setCreditId', null);
      commit('removeItemInCurrentList');
    },
    loadBadges({commit}) {
      Api.get(constants.endPoinds.creditCounter, true)
         .then(result => {
           commit('setBadges', result.data)
         })
    }
  }
}
