<template>
  <div :class="{root: true, sidebarClose, sidebarStatic}">
    <Sidebar/>
    <div class="wrap">
      <Header/>
      <div class="content" v-if="!graphicsActive && !reportsActive">
        <div class="row">
          <b-col xl="3" lg="3" sm="3" xs="12" class="p-0">
            <ListRequests/>
          </b-col>
          <b-col xl="9" lg="9" sm="9" xs="12" class="p-0">
            <RequestView/>
          </b-col>
        </div>
      </div>
      <div class="content" v-if="graphicsActive">
        <Graphics/>
      </div>
      <div class="content" v-if="qrCodeActive">
        <QrCode/>
      </div>
      <div class="content" v-if="reportsActive">
        <Reports/>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
  import {mapActions, mapState} from 'vuex';

  import Sidebar from '@/components/Sidebar/Sidebar';
  import Header from '@/components/Header/Header';
  import ListRequests from "@/components/ListRequets/ListRequests";
  import RequestView from "@/components/RequestView/RequestView";
  import auth from "../../helpers/auth";
  import Graphics from "../Graphics/Graphics";
  import QrCode from "../QrCode/QrCode";
  import Reports from "../Reports/Reports";
  import {constants} from "../../helpers";

  export default {
    name: 'Layout',
    components: {Graphics, QrCode, Reports, RequestView, ListRequests, Sidebar, Header},
    data: () => {
      return {
        user: auth.user
      }
    },
    methods: {
      ...mapActions('layout', ['toggleSidebar', 'changeSidebarActive']),
      ...mapActions('list_requests', ['loadRequests'])
    },
    computed: {
      ...mapState('layout', {
        sidebarClose: state => state.sidebarClose,
        sidebarStatic: state => state.sidebarStatic,
        graphicsActive: state => state.graphicsActive,
        qrCodeActive: state => state.qrCodeActive,
        reportsActive: state => state.reportsActive
      }),
      ...mapState('list_requests', {
        requestError: state => state.requestError
      })
    },
    created() {
      const staticSidebar = JSON.parse(localStorage.getItem('sidebarStatic'));
      if (staticSidebar) {
        this.$store.state.layout.sidebarStatic = true;
        this.$store.state.layout.sidebarClose = false;
      } else if (!this.sidebarClose) {
        setTimeout(() => {
          this.toggleSidebarState(null);
        }, 2500);
      }
      this.loadRequests({page: 1, status: constants.creditsStatus.incoming})
    },
    watch: {
      requestError: {
        handler: function () {
          this.$snotify.error('No se pudo cargar la petición intente mas tarde');
        }
      },
    }
  };
</script>

<style src="./Layout.scss" lang="scss"/>
