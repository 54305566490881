<template>
  <div class="request-view" v-if="creditDetails != null && creditDetails.credit_acquired == null">
    <div class="header" v-if="auth.user.company.id != 94 && auth.user.company.id != 101 && auth.user.company.id != 103 && auth.user.company.id != 113 && auth.user.company.id != 125 && auth.user.company.id != 126 && auth.user.company.id != 127 && auth.user.company.id != 128">
      <div class="row p-0 m-0">
        <div class="col-6 m-0 p-0">
          <h6 class="m-3">Número de solicitud: {{ credit_id }}</h6>
        </div>
        <div class="col-6 m-0 p-0">
          <b-button variant="warning" size="sm"
                    @click="exportPdf"
                    :disabled="downloadingReport"
                    class="btn-rounded-f pull-right m-1 mr-3"
                    :class="[downloadingReport ? 'biodisabled' : '']">
            <i class="la la-download"></i> Descargar reporte
          </b-button>
        </div>
      </div>
    </div>
    <div class="col-lg-12 pt-3">
      <div class="row">
        <div class="col-lg-8">
          <Profile :data="creditDetails.profile"/>
        </div>
        <div class="col-lg-4" v-if="auth.user.company.id != 94 && auth.user.company.id != 96 && auth.user.company.id != 101 && auth.user.company.id != 103 && auth.user.company.id != 113 && auth.user.company.id != 125 && auth.user.company.id != 126 && auth.user.company.id != 127 && auth.user.company.id != 128">
          <Score :data="creditDetails.score"/>
        </div>
        <div class="col-lg-4" v-if="auth.user.company.id == 94 || auth.user.company.id == 101 || auth.user.company.id == 96 || auth.user.company.id == 103 || auth.user.company.id == 113 || auth.user.company.id == 125 || auth.user.company.id == 126 || auth.user.company.id == 127 || auth.user.company.id == 128">
          <AvailableAmount :data="creditDetails.transactions"/>
        </div>
      </div>
    </div>
    <div class="pt-2 col-lg-12" v-if="auth.user.company.id != 94 && auth.user.company.id != 101 && auth.user.company.id != 96 && auth.user.company.id != 103 && auth.user.company.id != 113 && auth.user.company.id != 125 && auth.user.company.id != 126 && auth.user.company.id != 127 && auth.user.company.id != 128">
      <CreditDetails :data="creditDetails.credit" :userCreditHistory="creditDetails.historic_edupol" :creditData="creditDetails.credit"/>
    </div>
    <div class="pt-2 col-lg-12" v-if="creditDetails.credit.credit_status_id != 4">
      <div class="row" v-if="auth.user.company.id != 94 && auth.user.company.id != 101 && auth.user.company.id != 96 && auth.user.company.id != 103 && auth.user.company.id != 113 && auth.user.company.id != 125 && auth.user.company.id != 126 && auth.user.company.id != 127 && auth.user.company.id != 128">
        <div class="col-lg-5">
          <Form v-if="creditDetails.student == null" :data="creditDetails.fields" :userCreditHistory="creditDetails.historic_edupol" :reportedUserEdupol="creditDetails.reported_user_edupol" :creditData="creditDetails.credit" :riskCentral="creditDetails.risk_central"/>
          <Form v-if="creditDetails.student != null" :data="creditDetails.fields" :student="creditDetails.student" :userCreditHistory="creditDetails.historic_edupol" :reportedUserEdupol="creditDetails.reported_user_edupol" :collegeData="creditDetails.college_data" :creditData="creditDetails.credit" :riskCentral="creditDetails.risk_central"/>
        </div>
        <div class="col-lg-7">
          <Documents v-if="creditDetails.student == null" :data="creditDetails.documents"/>
          <Documents v-if="creditDetails.student != null" :data="creditDetails.documents"
                     :student="creditDetails.student"/>
          <div class="mt-2"></div>
          <NaturaRegion v-if="creditDetails.zone_manager" :data="creditDetails.zone_manager"/>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5" v-if="auth.user.company.id != 94 && auth.user.company.id != 101 && auth.user.company.id != 96 && auth.user.company.id != 103 && auth.user.company.id != 113 && auth.user.company.id != 125 && auth.user.company.id != 126 && auth.user.company.id != 127 && auth.user.company.id != 128">
          <div class="mt-2"></div>
          <Comments :data="creditDetails.comments" :class="[creditDetails.zone_manager ? 'increased' : '']"/>
        </div>
        <div class="col-lg-7" v-if="auth.user.company.id != 94 && auth.user.company.id != 101 && auth.user.company.id != 96 && auth.user.company.id != 103 && auth.user.company.id != 113 && auth.user.company.id != 125 && auth.user.company.id != 126 && auth.user.company.id != 127 && auth.user.company.id != 128">
          <div class="mt-2"></div>
          <Sarlaft :data="creditDetails.sarlaft" :class="[creditDetails.zone_manager ? '' : 'increased']"/>
        </div>
      </div>

      <div class="row" v-if="creditDetails.reason_rejection">
        <div class="col-lg-5" v-if="auth.user.company.id != 94 && auth.user.company.id != 101 && auth.user.company.id != 96 && auth.user.company.id != 103 && auth.user.company.id != 113 && auth.user.company.id != 125 && auth.user.company.id != 126 && auth.user.company.id != 127 && auth.user.company.id != 128">
          <div class="mt-2"></div>
          <ReasonRejection :data="creditDetails.reason_rejection" :class="[creditDetails.zone_manager ? 'increased' : '']"/>
        </div>
      </div>

      <div class="row" v-if="auth.user.company.id == 94 || auth.user.company.id == 101 || auth.user.company.id == 96 || auth.user.company.id == 103 || auth.user.company.id == 113 || auth.user.company.id == 125 || auth.user.company.id == 126 || auth.user.company.id == 127 || auth.user.company.id == 128">
        <div class="col-lg-6">
          <div class="mt-2"></div>
          <TransactionsHistory :data="creditDetails.transactions"
                               :class="[creditDetails.zone_manager ? 'increased' : '']"/>
        </div>
        <div class="col-lg-6">
          <div class="mt-2"></div>
          <Transactions :data="creditDetails.transactions" :class="[creditDetails.zone_manager ? 'increased' : '']"/>
        </div>
      </div>
    </div>

    <!-- DOCUMENT VERIFY -->
    <div class="pt-2 col-lg-12" v-if="false">
      <DocumentVerify :data="creditDetails.verify_document_data" :credit="creditDetails.credit" :datacredito="creditDetails.datacredito_response"/>
    </div>
    <!-- DOCUMENT VERIFY -->

    <div class="pt-2 col-lg-12 mt-5 mb-5"
         v-if="creditDetails.credit.qr_company_flag && (currentStatus === 1 || currentStatus === 15)">
      <div class="text-center">
        <button @click="$refs.modal_approved_amount.show()"
                class="btn btn-rounded-f natura_analyze natura-button">Analizar
        </button>
      </div>
    </div>

    <div class="pt-2 col-lg-12 mt-5 mb-5"
         v-if="(currentStatus === 17 || currentStatus === 18) || (creditDetails.status_config && (currentStatus === 1 || currentStatus === 15))">
      <div class="text-center">
        <button @click="$refs.modal_cemex_amount.show()"
                class="btn btn-rounded-f natura_analyze natura-button">Analizar
        </button>
      </div>
    </div>
    <div
        v-else-if="!creditDetails.credit.qr_company_flag && currentStatus === 1 && !creditDetails.status_config && auth.user.user.core_role_id != 7 && auth.user.company.id != 94 && auth.user.company.id != 96 && auth.user.company.id != 101 && auth.user.company.id != 103 && auth.user.company.id != 113 && auth.user.company.id != 125 && auth.user.company.id != 126 && auth.user.company.id != 127 && auth.user.company.id != 128"
        class="pt-2 col-lg-12 mt-5 mb-5">
      <div class="text-center">

        <button v-if="creditDetails.credit.entity_id == 137 && creditDetails.cifin_information"
                @click="$refs.cifin_information.show()" class="btn btn-rounded-f btn-info mb-3">
          Informacion de cifin
        </button>

        <div v-if="auth.user.company.id != 90">
          <button @click="aprove_c" class="btn btn-rounded-f btn-success"><i
              class="la la-check-circle"></i> Aprobar
          </button>
          <!--<button @click="return_c" class="btn btn-rounded-f btn-warning"><i class="la la-minus-circle"></i> Devolver
          </button>-->
          <!--        <button @click="refuse_c" class="btn btn-rounded-f btn-danger"><i
                      class="la la-times-circle"></i> Rechazar
                  </button>-->
          <button @click="$refs.modal_reason_rejection.show()" class="btn btn-rounded-f btn-danger"><i
              class="la la-times-circle"></i> Rechazar
          </button>

          <button v-if="creditDetails.credit.entity_id == 90" @click="approved50" class="btn btn-rounded-f btn-danger">
            <i class="la la-times-circle"></i> Aprobar al 50%
          </button>
        </div>

        <button v-if="creditDetails.credit.entity_id == 90 && creditDetails.historic_edupol.length > 0"
                @click="$refs.modal_payment_behavior.show()" class="btn btn-rounded-f btn-info mt-3">
          Comportamiento de pago
        </button>

      </div>
    </div>

    <!-- BUTTONS FOR CANCEL NOTIFY AND OTHER FUNCTIONS -->
    <div
        v-else-if="creditDetails.credit.entity_id == 90 && (auth.user.user.core_role_id == 7 || auth.user.user.core_role_id == 1 || auth.user.user.core_role_id == 2) && valid_show_buttons.includes(creditDetails.credit.credit_status_id)"
        class="pt-2 col-lg-12 mt-5 mb-5">
      <div class="text-center">
        <button @click="notify_teseo" class="btn btn-rounded-f btn-info"
                v-if="valid_show_notify_buttons.includes(creditDetails.credit.credit_status_id)">
          <i class="la la-mail-forward"></i>Notificar Teseo
        </button>
        <!--<button @click="return_c" class="btn btn-rounded-f btn-warning"><i class="la la-minus-circle"></i> Devolver
        </button>-->
        <button @click="notify_user" class="btn btn-rounded-f btn-info"
                v-if="valid_show_notify_buttons.includes(creditDetails.credit.credit_status_id)">
          <i class="la la-mail-reply"></i>Notificar Usuario (enviar todos los correos)
        </button>
        <button @click="cancel_credit" class="btn btn-rounded-f btn-danger"
                v-if="!creditDetails.credit.sign_new_terms && creditDetails.credit.credit_status_id == 21">
          <i class="la la-mail-reply"></i>Cancelar
        </button>

      </div>
    </div>

    <div v-if="creditDetails.credit.entity_id == 93" class="text-center mb-3">
      <b-button v-b-modal.tecnocoop_modal variant="primary" class="btn btn-rounded-f">
        <i class="la la-address-book"></i>Resultados Cifin
      </b-button>
    </div>

    <!-- BUTTONS FOR CANCEL NOTIFY AND OTHER FUNCTIONS -->
    <b-modal size="sm" ref="modal" title="Devolver crédito" modal-footer="false">
      <div class="form-group">
        <label for="comentario">Agregar un comentario</label>
        <textarea name="comment" class="form-control" id="comentario" ref="comment" rows="4"
                  minlength="10" maxlength="300"></textarea>
      </div>
      <div slot="modal-footer" class="text-right">
        <b-button size="sm" variant="danger" @click="hideModal">Cancelar</b-button>
        <b-button size="sm" variant="warning" @click="completeReturn" class="ml-1">Enviar</b-button>
      </div>
    </b-modal>
    <b-modal
        id="modalNatura"
        ref="modal_approved_amount" centered
        hide-footer
        hide-header
        size="natura">
      <div class="form-group">
        <div class="natura-title">
          <div class="natura-title-text">
            {{ creditDetails.credit.motal_tile }}
          </div>
          <img ref="naturaClose" class="natura-title-close" @click="hideAmountModal"
               @mouseover='$refs.naturaClose.src="/assets/close_hover.png"'
               @mouseleave='$refs.naturaClose.src="/assets/close.png"' src="/assets/close.png"/>
        </div>
        <div class="row centered justify-content-center">
          <div class="natura-amount col-7">
            {{ creditDetails.credit.prefix_pr_points + creditDetails.credit.pr_points }}
          </div>
        </div>
        <div class="row centered justify-content-center">
          <div style="font-size: x-large; margin: 20px;">
            APROBACIÓN <span class="pr-span"> (PR{{ creditDetails.credit.pr_value }})</span>
          </div>
        </div>
        <div class="row natura-lists-container centered">
          <div class="col-md-6 col-sm">
            <span>¿Se encuentra reportado<br>en listas restrictivas?</span>
            <div class="natura-checks-container">
              <div>Si<img
                  :src='(internalList != null && internalList == true) ? "/assets/check.png" : "/assets/check_no.png"'
                  @click="internalList = true"/></div>
              <div>No<img
                  :src='(internalList != null && internalList == false) ? "/assets/check.png" : "/assets/check_no.png"'
                  @click="internalList = false"/></div>
            </div>
          </div>
          <div class="col-md-6 col-sm">
            <span>¿Se encuentra reportado<br>en ACOVEDI?</span>
            <div class="natura-checks-container">
              <div>Si<img :src='secondList != null && secondList == true ? "/assets/check.png" : "/assets/check_no.png"'
                          @click="secondList = true"/></div>
              <div>No<img
                  :src='secondList != null && secondList == false ? "/assets/check.png" : "/assets/check_no.png"'
                  @click="secondList = false"/></div>
            </div>
          </div>
        </div>
        <div class="row natura-option-lists centered">
          <div class="col-md-12 col-lg-6 right-aligned">
            <select v-model="manager" @change="refreshSectors">
              <option disabled value="null" selected>Seleccione Gerente</option>
              <option v-for="option in creditDetails.manager_list" v-bind:value="option"
                      :key='"k"+option.id'>{{ option.name }}
              </option>
            </select>
          </div>
          <div class="col-md-12 col-lg-6 left-aligned mt-3 mt-lg-0" v-if="manager != null">
            <select v-model="sector">
              <option disabled value="null" selected>Seleccione Sector</option>
              <option v-for="option in manager.values" v-bind:value="option"
                      :key='"s"+option.id'>{{ option.sector }}
              </option>
            </select>
          </div>
          <div v-else class="col-md-12 col-lg-6 left-aligned mt-3 mt-lg-0">
            <select disabled>
              <option selected="selected">Seleccione Gerente antes</option>
            </select>
          </div>
        </div>
        <div class="row centered natura-option-name-email">
          <div class="col-md-6 col-sm">
            <i>{{ sector != null ? sector.name : "" }}</i>
          </div>
          <div class="col-md-6 col-sm">
            <i>{{ sector != null ? sector.email : "" }}</i>
          </div>
        </div>
      </div>
      <div class="centered natura-buttons" v-if="internalList != null">
        <b-button class="btn btn-rounded-f btn-danger natura-button"
                  :disabled="internalList == null || secondList == null || manager == null || sector == null"
                  @click='setNaturaAction("rechazar")'>
          <i class="la la-times-circle"></i> Rechazar
        </b-button>
        <b-button class="btn btn-rounded-f btn-success natura-button" v-if="internalList == false"
                  @click='setNaturaAction("aprobar")'
                  :disabled="internalList == null || secondList == null || manager == null || sector == null">
          <i class="la la-check-circle"></i> Aprobar
        </b-button>
        <b-button class="btn btn-rounded-f btn-pending natura-button"
                  v-if="internalList == false && creditDetails.credit.show_pending_button && currentStatus !== 15"
                  @click='setNaturaAction("pendiente")'
                  :disabled="internalList == null || secondList == null || manager == null || sector == null">
          <i class="la la-clock-o"></i> Pendiente
        </b-button>
      </div>
    </b-modal>

    <b-modal
        v-if="creditDetails.status_config"
        id="modalCemex"
        ref="modal_cemex_amount" centered
        hide-footer
        hide-header
        size="natura">
      <div class="form-group">
        <div class="natura-title">
          <div class="natura-title-text">
            {{ creditDetails.status_config.config.title }}
          </div>
          <img ref="cemexClose" class="natura-title-close" @click="hideCemexModal"
               @mouseover='$refs.cemexClose.src="/assets/close_hover.png"'
               @mouseleave='$refs.cemexClose.src="/assets/close.png"' src="/assets/close.png"/>
        </div>
        <div class="row centered justify-content-center">
          <div style="font-size: x-large; margin: 20px;">
            {{ creditDetails.status_config.config.body }}
          </div>
        </div>
        <div class="row natura-lists-container centered justify-content-center">
          <div class="col-md-12 col-lg-6">
            <select v-model="newCreditStatus">
              <option disabled value="null" selected>Seleccione estado</option>
              <option v-for="option in creditDetails.status_config.status" v-bind:value="option"
                      :key='"k"+option.id'>{{ option.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="centered natura-buttons">
        <b-button class="btn btn-rounded-f btn-success cemex-button"
                  @click='setCemexAction("estado")'
                  :disabled="newCreditStatus == null">
          <i class="la la-check-circle"></i> Cambiar estado
        </b-button>
      </div>
    </b-modal>

    <!-- MODAL PARA SELECCIONAR LA RAZON DE RECHAZO -->
    <b-modal
        id="modalReasonRejection"
        ref="modal_reason_rejection" centered
        hide-footer
        hide-header
        size="natura"
        style="top: 1% !important;">
      <div class="form-group">
        <div class="natura-title">
          <div class="natura-title-text">
            Seleccione la razón de rechazo
          </div>
        </div>
        <div class="row natura-lists-container centered justify-content-center">
          <div class="col-md-12 col-lg-6 my-4">
            <select v-model="reasonRejection" @change="changeReason()">
              <option value="null" selected>Selecciona una opción</option>
              <option value="Capacidad de endeudamiento.">Capacidad de endeudamiento.</option>
              <option value="Reporte en centrales de riesgo.">Reporte en centrales de riesgo.</option>
              <option value="Cliente no cotizante.">Cliente no cotizante.</option>
              <option value="Sin experiencia crediticia.">Sin experiencia crediticia.</option>
              <option value="Score Bajo.">Score Bajo.</option>
              <option value="Huellas de consulta.">Huellas de consulta.</option>
            </select>
          </div>
        </div>
      </div>
      <div class="centered text-danger" v-if="errorMessageStatus">
        <p>{{ errorMessage }}</p>
      </div>
      <div class="centered natura-buttons">
        <b-button class="btn btn-rounded-f btn-danger cemex-button"
                  @click='refuse_c()'>
          <i class="la la-check-circle"></i> Rechazar
        </b-button>
      </div>
    </b-modal>

    <!-- MODAL PARA MOSTRAR LA INFORMACION DE CIFIN -->
    <b-modal
        id="modalPaymentBehavior"
        ref="cifin_information"
        centered
        hide-footer
        hide-header
        size="natura"
        style="top: 1% !important;">
      <div class="form-group">
        <div class="natura-title">
          <div class="natura-title-text">
            Informacion de cifin
          </div>
        </div>
      </div>

      <h5 class="text-center mt-4">Para ver cada uno de los elementos con claridad use un Json formatter</h5>

      <hr>

      <div class="datacredito-html px-4">
        {{ creditDetails.cifin_information  }}
      </div>

    </b-modal>

    <!-- MODAL PARA SELECCIONAR LA RAZON DE RECHAZO -->
    <b-modal
        id="modalPaymentBehavior"
        ref="modal_payment_behavior" centered
        hide-footer
        hide-header
        size="natura"
        style="top: 1% !important;">
      <div class="form-group">
        <div class="natura-title">
          <div class="natura-title-text">
            Comportamiento de pago
          </div>
        </div>

        <div class="mt-5 row natura-lists-container centered justify-content-center">
          <p>Selecciona el comportamiento de pago de este usuario</p>
        </div>

        <div class="row natura-lists-container centered justify-content-center">
          <div class="col-md-12 col-lg-6 my-4">
            <select v-model="reasonRejection" ref="payment_behavoir_value">
              <option value="null" selected>Selecciona una opción</option>
              <option value="good_pay">Bueno</option>
              <option value="bad_pay">Malo</option>
            </select>
          </div>
        </div>
      </div>
      <div class="centered text-danger" v-if="errorMessageStatus">
        <p>{{ errorMessage }}</p>
      </div>
      <div class="centered natura-buttons">
        <b-button class="btn btn-rounded-f btn-success cemex-button"
                  @click='sendPaymentBehavior()'>
          <i class="la la-check-circle"></i> Enviar
        </b-button>
      </div>
    </b-modal>

    <!-- MODAL TECNOCOOP -->
    <b-modal size="natura" id="tecnocoop_modal" ref="tecnocoop_modal"
             title="Informacion de Tecnocoop" ok-only>
      <div class="datacredito-html" v-html="creditDetails.tecnocoop_response">
        {{ creditDetails.tecnocoop_response }}
      </div>
    </b-modal>

  </div>
  <div class="request-view unread text-center pt-5" v-else>
    <div v-if="creditDetails != null && creditDetails.credit_acquired">
      <img src="../../assets/icons/credit_acquired.png"/>
      <h3 class="m-0">{{ creditDetails.message }}</h3>
      <b-button variant="warning" size="sm"
                @click="reloadContent"
                class="btn-rounded-f  m-5 align-center">
        <i class="la la-refresh"></i> {{ creditDetails.button_name }}
      </b-button>
    </div>
    <div v-else-if="!isDetailLoading">
      <img src="../../assets/icons/selecciona-credito.png"/>
      <h3 class="m-0">Seleccione un crédito</h3>
      <h2 class="m-0">para leerlo</h2>
    </div>
    <div class="pt-5" v-else>
      <div>
        <i class="fa fa-5x fa-spinner fa-spin"></i>
        <h3>Cargando detalle...</h3>
      </div>
    </div>



  </div>




</template>

<script>
import {mapState, mapActions} from 'vuex';
import {Api, constants} from "../../helpers";
import Profile from "./Detail/Profile";
import Score from "./Detail/Score";
import CreditDetails from "./Detail/CreditDetails";
import Form from "./Detail/Form";
import Documents from "./Detail/Documents";
import Comments from "./Detail/Comments";
import ReasonRejection from "./Detail/ReasonRejection";
import Sarlaft from "./Detail/Sarlaft";
import NaturaRegion from "./Detail/NaturaRegion";
import Transactions from "./Detail/Transactions";
import TransactionsHistory from "./Detail/TransactionsHistory";
import AvailableAmount from "./Detail/AvailableAmount";
import StudentInfo from "./Detail/StudentInfo";
import DocumentVerify from "./Detail/DocumentVerify";
import auth from "@/helpers/auth";

export default {
  name: "RequestView",
  components: {
    NaturaRegion,
    Comments,
    Documents,
    ReasonRejection,
    Form,
    CreditDetails,
    Score,
    Profile,
    Sarlaft,
    Transactions,
    TransactionsHistory,
    AvailableAmount,
    StudentInfo,
    DocumentVerify
  },
  data() {
    return {
      internalList: null,
      secondList: null,
      manager: null,
      sector: null,
      newCreditStatus: null,
      reasonRejection: null,
      downloadingReport: false,
      auth: auth,
      valid_show_buttons: [1, 2, 3, 21, 22],
      valid_show_notify_buttons: [2, 3, 21, 22],
      errorMessageStatus: false,
      errorMessage: ''
    }
  },
  computed: {
    ...mapState('list_requests', {
      creditDetails: state => state.itemDetail,
      credit_id: state => state.credit_id,
      isDetailLoading: state => state.isDetailLoading,
      currentStatus: state => state.status,
    })
  },
  methods: {
    ...mapActions('list_requests', [
      'removeCurrentInList', 'loadBadges', 'loadRequests'
    ]),

    sendPaymentBehavior() {
      this.$refs.modal_payment_behavior.hide();
      let value = this.$refs.payment_behavoir_value.value;
      console.log('payment_behavoir_value', value);
      if (value != null) {
        this.$snotify.async('Enviando comportamiento', '', () => new Promise((resolve, reject) => {
          Api.post(constants.endPoinds.paymentBehavior, {
            credit_id: this.credit_id,
            behavior: value
          }, true).then((response) => {
            this.loadBadges();

            if (response.data.status) {
              this.remove();
              resolve({
                title: '¡Aceptado!',
                body: response.data.message,
                config: {
                  closeOnClick: true, timeout: 10000
                }
              })
            } else {
              reject({
                title: 'Solicitud actualizada',
                body: response.data.message,
                config: {
                  closeOnClick: true, timeout: 10000
                }
              })
            }


          }).catch(() => {
            reject({
              title: '¡Error!',
              body: 'No se envio el comportamiento',
              config: {
                closeOnClick: true, timeout: 3000
              }
            })
          })
        }, 3000));
      } else {
        this.$snotify.warning('Debes seleccionar una opción valida', 'Atención!')
      }
    },
    formatAmount(amount) {
      return amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: '0',
        minimumFractionDigits: '0'
      });
    },
    hideCemexModal() {
      this.$refs.modal_cemex_amount.hide();
    },
    hideAmountModal() {
      this.$refs.modal_approved_amount.hide();
    },
    refreshSectors() {
      this.sector = {};
      setTimeout(function () {
        this.sector = null
      }.bind(this), 1);
    },
    setCemexAction() {
      this.$refs.modal_cemex_amount.hide();
      let message = "¿Seguro de asignar el estado '" + this.newCreditStatus.name + "'?";
      let status = this.newCreditStatus.id;
      this.$snotify.confirm(message, '', {
        buttons: [
          {
            text: 'NO', action: (toast) => {
              this.$snotify.remove(toast.id)
            }
          },
          {
            text: 'SI', action: (toast) => {
              this.$snotify.remove(toast.id);
              this.$snotify.async('Enviando cambio', 'Cambiando estado', () => new Promise((resolve, reject) => {
                Api.post(constants.endPoinds.creditApproveWithAmount, {
                  credit_id: this.credit_id,
                  status: status
                }, true).then(() => {
                  this.remove();
                  this.loadBadges();
                  resolve({
                    title: 'Solicitud actualizada',
                    body: 'Estado cambiado correctamente',
                    config: {
                      closeOnClick: true, timeout: 3000
                    }
                  })
                }).catch(() => {
                  reject({
                    title: 'Error actualizando la solicitud',
                    body: 'No se cambio el estado',
                    config: {
                      closeOnClick: true, timeout: 3000
                    }
                  })
                })
              }));
            }
          },
        ]
      });
    },
    setNaturaAction(option) {
      this.$refs.modal_approved_amount.hide();
      let message = "";
      let status = constants.creditsStatus.approved;
      switch (option) {
        case "aprobar":
          message = '¿Esta seguro de aprobar la solicitud?';
          status = constants.creditsStatus.approved;
          break;
        case "rechazar":
          message = '¿Esta seguro de rechazar la solicitud?';
          status = constants.creditsStatus.denied;
          break;
        case "pendiente":
          message = '¿Esta seguro de actualizar la solicitud como pendiente?';
          status = constants.creditsStatus.pendingNatura;
          break;
      }
      this.$snotify.confirm(message, '', {
        buttons: [
          {
            text: 'NO', action: (toast) => {
              this.$snotify.remove(toast.id)
            }
          },
          {
            text: 'SI', action: (toast) => {
              this.$snotify.remove(toast.id);
              this.$snotify.async('Enviando cambio', 'Cambiando estado', () => new Promise((resolve, reject) => {
                Api.post(constants.endPoinds.creditApproveWithAmount, {
                  credit_id: this.credit_id,
                  status: status,
                  manager_id: this.manager.id,
                  sector_id: this.sector.id,
                  internal_list: this.internalList,
                  acovedi_list: this.secondList,
                  pr_points: this.creditDetails.credit.pr_points
                }, true).then(() => {
                  this.remove();
                  this.loadBadges();
                  resolve({
                    title: 'Solicitud actualizada',
                    body: 'Estado cambiado correctamente',
                    config: {
                      closeOnClick: true, timeout: 3000
                    }
                  })
                }).catch(() => {
                  reject({
                    title: 'Error actualizando la solicitud',
                    body: 'No se cambio el estado',
                    config: {
                      closeOnClick: true, timeout: 3000
                    }
                  })
                })
              }));
            }
          },
        ]
      });
    },
    aprove_c() {
      this.$snotify.confirm('¿Esta seguro de aprobar la solicitud?', '', {
        buttons: [
          {
            text: 'NO', action: (toast) => {
              this.$snotify.remove(toast.id)
            }
          },
          {
            text: 'SI', action: (toast) => {
              this.$snotify.remove(toast.id);
              this.$snotify.async('Enviando aprobación', 'Cambiando estado', () => new Promise((resolve, reject) => {
                Api.post(constants.endPoinds.changeStatus, {
                  credit_id: this.credit_id,
                  status: constants.creditsStatus.approved
                }, true).then((response) => {
                  this.loadBadges();
                  if (response.data.status) {
                    this.remove();
                    resolve({
                      title: '¡Aceptado!',
                      body: response.data.message,
                      config: {
                        closeOnClick: true, timeout: 10000
                      }
                    })
                  } else {
                    reject({
                      title: 'Solicitud actualizada',
                      body: response.data.message,
                      config: {
                        closeOnClick: true, timeout: 10000
                      }
                    })
                  }
                }).catch(() => {
                  reject({
                    title: '¡Error!',
                    body: 'No se cambio el estado',
                    config: {
                      closeOnClick: true, timeout: 3000
                    }
                  })
                })
              }));
            }
          },
        ]
      });
    },
    cancel_credit() {
      this.$snotify.confirm('¿Esta seguro de re notificar a teseo?', '', {
        buttons: [
          {
            text: 'NO', action: (toast) => {
              this.$snotify.remove(toast.id)
            }
          },
          {
            text: 'SI', action: (toast) => {
              this.$snotify.remove(toast.id);
              this.$snotify.async('Cancelando', 'Cambiando estado', () => new Promise((resolve, reject) => {
                Api.post(constants.endPoinds.cancelCredit, {
                  credit_id: this.credit_id,
                }, true).then(() => {
                  this.remove();
                  this.loadBadges();
                  resolve({
                    title: '¡Aceptado!',
                    body: 'Credito cancelado',
                    config: {
                      closeOnClick: true, timeout: 7000
                    }
                  })
                }).catch(() => {
                  reject({
                    title: '¡Error!',
                    body: 'No se pudo cancelar',
                    config: {
                      closeOnClick: true, timeout: 7000
                    }
                  })
                })
              }));
            }
          },
        ]
      });
    },
    notify_user() {
      this.$snotify.confirm('¿Esta seguro de re enviar todos los correos?', '', {
        buttons: [
          {
            text: 'NO', action: (toast) => {
              this.$snotify.remove(toast.id)
            }
          },
          {
            text: 'SI', action: (toast) => {
              this.$snotify.remove(toast.id);
              this.$snotify.async('Enviando Todas las Notificaciones', 'Enviando ...', () => new Promise((resolve, reject) => {
                Api.post(constants.endPoinds.reNotifyAll, {
                  credit_id: this.credit_id,
                }, true).then(() => {
                  resolve({
                    title: '¡Aceptado!',
                    body: 'Notificaciones enviadas',
                    config: {
                      closeOnClick: true, timeout: 7000
                    }
                  })
                }).catch(() => {
                  reject({
                    title: '¡Error!',
                    body: 'No se pudo notificar',
                    config: {
                      closeOnClick: true, timeout: 7000
                    }
                  })
                })
              }));
            }
          },
        ]
      });
    },
    notify_teseo() {
      this.$snotify.confirm('¿Esta seguro de re notificar a teseo?', '', {
        buttons: [
          {
            text: 'NO', action: (toast) => {
              this.$snotify.remove(toast.id)
            }
          },
          {
            text: 'SI', action: (toast) => {
              this.$snotify.remove(toast.id);
              this.$snotify.async('Enviando Notificacion', 'Cambiando estado', () => new Promise((resolve, reject) => {
                Api.post(constants.endPoinds.notifyTeseo, {
                  credit_id: this.credit_id,
                }, true).then(() => {
                  resolve({
                    title: '¡Aceptado!',
                    body: 'Notificacion enviada',
                    config: {
                      closeOnClick: true, timeout: 7000
                    }
                  })
                }).catch(() => {
                  reject({
                    title: '¡Error!',
                    body: 'No se pudo notificar',
                    config: {
                      closeOnClick: true, timeout: 7000
                    }
                  })
                })
              }));
            }
          },
        ]
      });
    },
    return_c() {
      this.$refs.modal.show();
    },
    completeReturn(evt) {
      evt.preventDefault();
      this.$refs.modal.hide();
      let value = this.$refs.comment.value;
      if (value.length >= 15 && value.length <= 300) {
        this.$snotify.async('Enviando aprobación', '', () => new Promise((resolve, reject) => {
          Api.post(constants.endPoinds.changeStatus, {
            credit_id: this.credit_id,
            status: constants.creditsStatus.returned,
            comment: value
          }, true).then(() => {
            this.remove();
            this.loadBadges();
            resolve({
              title: '¡Aceptado!',
              body: 'Estado cambiado correctamente',
              config: {
                closeOnClick: true, timeout: 3000
              }
            })
          }).catch(() => {
            reject({
              title: '¡Error!',
              body: 'No se cambio el estado',
              config: {
                closeOnClick: true, timeout: 3000
              }
            })
          })
        }, 3000));
      } else {
        this.$snotify.warning('El mensaje debe tener entre 15 y 300 caracteres', 'Atención!')
      }
    },
    refuse_c() {
      console.log(this.reasonRejection);

      if(this.reasonRejection != 'null' && this.reasonRejection != null) {

        this.$snotify.confirm('¿Esta seguro de rechazar la solicitud?', '', {
          buttons: [
            {
              text: 'NO', action: (toast) => {
                this.$snotify.remove(toast.id)
              }
            },
            {
              text: 'SI', action: (toast) => {
                this.$snotify.remove(toast.id);
                this.$snotify.async('Enviando rechazo', 'Cambiando estado', () => new Promise((resolve, reject) => {
                  Api.post(constants.endPoinds.changeStatus, {
                    credit_id: this.credit_id,
                    status: constants.creditsStatus.denied,
                    reason_rejection: this.reasonRejection
                  }, true).then(response => {
                    this.loadBadges();
                    if (response.data.status) {
                      this.remove();
                      resolve({
                        title: '¡Aceptado!',
                        body: response.data.message,
                        config: {
                          closeOnClick: true, timeout: 10000
                        }
                      })
                    } else {
                      reject({
                        title: 'Solicitud actualizada',
                        body: response.data.message,
                        config: {
                          closeOnClick: true, timeout: 10000
                        }
                      })
                    }
                  }).catch(() => {
                    reject({
                      title: '¡Error!',
                      body: 'No se cambio el estado',
                      config: {
                        closeOnClick: true, timeout: 3000
                      }
                    })
                  })
                }));
              }
            },
          ]
        });

      } else {
        this.errorMessageStatus = true;
        this.errorMessage = 'Debes seleccionar una razón de rechazo';
      }
    },
    changeReason() {
      if(this.reasonRejection) {
        this.errorMessageStatus = false;
        this.errorMessage = '';
      }
    },
    approved50() {
      this.$snotify.confirm('¿Esta seguro de aprobar al 50% la solicitud?', '', {
        buttons: [
          {
            text: 'NO', action: (toast) => {
              this.$snotify.remove(toast.id)
            }
          },
          {
            text: 'SI', action: (toast) => {
              this.$snotify.remove(toast.id);
              this.$snotify.async('Enviando aprobación', 'Cambiando estado', () => new Promise((resolve, reject) => {
                Api.post(constants.endPoinds.changeStatus, {
                  credit_id: this.credit_id,
                  status: constants.creditsStatus.approved_50
                }, true).then(() => {
                  this.remove();
                  this.loadBadges();
                  resolve({
                    title: '¡Aceptado!',
                    body: 'Estado cambiado correctamente',
                    config: {
                      closeOnClick: true, timeout: 3000
                    }
                  })
                }).catch(() => {
                  reject({
                    title: '¡Error!',
                    body: 'No se cambio el estado',
                    config: {
                      closeOnClick: true, timeout: 3000
                    }
                  })
                })
              }));
            }
          },
        ]
      });
    },
    hideModal() {
      this.$refs.modal.hide();
    },
    remove() {
      this.removeCurrentInList()
    },
    exportPdf() {
      this.downloadingReport = true;
      Api.download(`${constants.endPoinds.exportPdfDetail}?credit_id=${this.credit_id}`,
          `detail-credit-${this.credit_id}.pdf`, true, function downloaded() {
            this.downloadingReport = false;
          }.bind(this))
    },
    reloadContent() {
      this.loadRequests({page: 1, status: constants.creditsStatus.incoming})
    }
  },
  created() {
    this.loadBadges();
    this.$root.$on('bv::modal::show', function () {
      this.internalList = null;
      this.secondList = null;
      this.manager = null;
      this.sector = null;
      this.newCreditStatus = null;
    }.bind(this));
  }
}
</script>

<style src="./RequestView.scss" lang="scss">
.modal.show .modal-dialog .modal-natura {
  width: 800px !important;
}



</style>
