<template>
  <div class="card score" style="height: 100%;">
    <div class="card-body">
      <h4 class="card-title text-center">
        Score
      </h4>
      <div v-if="data.average != null">
        <div class="text-center">
          <img :src="data.image_score" class="img-fluid">
        </div>
      </div>
      <div v-else class="pt-5 text-center">
        <i class="fa fa-info-circle" style="font-size: 30px"></i>
        <h2>Sin Score</h2>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "Score",
    props: {
      data: {type: Object, default: null}
    },
  }
</script>

<style lang="scss" scoped>
  @import '../../../styles/app';

  .score {
    .card-body {
      padding: 1rem;
    }
    .card-title {
      border-bottom: 1px solid $gray-600;
      padding-bottom: 1rem;
    }
    hr {
      margin: 0;
    }
  }
</style>