export const serializer = {
    get
};

function get(obj, prefix) {
    let str = [], p;
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            let k = prefix ? prefix + "[" + p + "]" : p,
                v = obj[p];
            if (v instanceof Date) {
                str.push(encodeURIComponent(k) + "=" + encodeURIComponent(v.getTime()));
            } else {
                if ((v !== null && typeof v === "object")){
                  str.push(get(v, k));
                } else {
                    str.push(encodeURIComponent(k) + "=" + encodeURIComponent(v));
                }
            }
        }
    }
    return '?'+str.join("&");
}