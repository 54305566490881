<template>
  <div class="card profile">
    <div class="card-body">
      <h4 class="card-title text-center">
        Información del Estudiante
      </h4>
      <div class="row">
        <div class="col-sm-3 text-center">
          <!--          transform: rotate(90deg);-->
          <!-- CLASE PARA ROTAR LA IMAGEN -->
          <!--  v-bind:class="[data.device_rotate ? 'rotate-img' : '']" -->
          <img :src="data.profile_image"
               class="img-profile img-fluid"/>
        </div>
        <div class="col-sm-3 p-3">
          <h3>Nombres</h3>
          <p>{{data.first_name}}</p>
          <h3>Fecha de nacimiento</h3>
          <p>{{data.birthdate}}</p>
          <h3>E-mail</h3>
          <p>{{data.email}}</p>
        </div>
        <div class="col-sm-3 p-3">
          <h3>Apellidos</h3>
          <p>{{data.first_last_name}}</p>
          <h3>N° Documento</h3>
          <p>{{data.document_id}}</p>
          <h3>Celular</h3>
          <p>{{data.phone}}</p>
        </div>
        <div class="col-sm-3 p-3">
          <button class="btn btn-sm btn-clear btn-block btn-docs" v-b-toggle="'12'">
            <div>Documento foto frontal</div>
          </button>
          <b-collapse accordion="accordion" id="12" class="mt-2 pt-2 pb-2">
            <div class="pl-5">
              <img :src="data.document_image_front"
                   class="img-fluid"/>
            </div>
          </b-collapse>
          <button class="btn btn-sm btn-clear btn-block btn-docs" v-b-toggle="'13'">
            <div>Documento foto reverso</div>
          </button>
          <b-collapse accordion="accordion" id="13" class="mt-2 pt-2 pb-2">
            <div class="pl-5">
              <img :src="data.document_image_back"
                   class="img-fluid"/>
            </div>
          </b-collapse>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Profile",
  props: {
    data: {type: Object, default: null}
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/app';

.profile {
  .card-body {
    padding: 1rem;
  }

  .card-title {
    border-bottom: 1px solid $gray-600;
    padding-bottom: 1rem;
  }

  .img-profile {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    height: 150px;
    width: 150px;
    object-fit: cover;
  }

  h3 {
    color: $warning;
    font-weight: 500;
    font-size: 1.15rem;
    margin: 0;
  }

  .rotate-img {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.btn-docs {
  background: #ffffff;
  text-align: left;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-size: larger;
  border-bottom: 1px solid $gray-200;
  color: $gray-600;
  i {
    color: $warning;
  }
}
</style>