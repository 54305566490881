<template>
  <div class="comments">
    <div class="card h-260">
      <div class="card-body pl-4 pr-4">
        <h4 class="card-title text-center">
          Comentarios de analista
        </h4>
        <div class="scroll">
          <div class="item" v-for="(item, index) in data" v-bind:key="index">
            <p class="m-0">{{ item.analyst_name }}</p>
            <p class="p-0 m-0 text-warning">{{ item.comment }}</p>
            <small class="text-muted">{{ format(item.create_at) }}</small>
            <hr>
          </div>
        </div>
        <b-form @submit="submitComment" v-if="auth.user.user.core_role_id != 7">
          <div class="form-group">
            <label for="comment">Comentario</label>
            <textarea name="comment"
                      minlength="15" maxlength="300"
                      id="comment" ref="comment" rows="4" placeholder="Escribe tu comentario"
                      class="form-control"></textarea>
          </div>
          <div class="text-right">
            <button class="btn btn-success pull-right" type="submit"> Agregar</button>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import moment from 'moment'
import {constants} from "../../../helpers";
import {Api} from "../../../helpers/api";
import auth from "../../../helpers/auth";


export default {
  name: "Comments",
  props: {
    data: {
      type: Array, default: null
    }
  },
  computed: {
    ...mapState('list_requests', {
      creditDetails: state => state.itemDetail,
      credit_id: state => state.credit_id
    })
  },
  data: () => {
    return {
      auth: auth,
    }
  },
  methods: {
    ...mapActions('list_requests', ['nextPage', 'loadRequests', 'loadDetail', 'setDetail', 'addComment']),
    format(date) {
      return moment(date).format('YYYY/MM/DD hh:ss a')
    },
    submitComment(evt) {
      evt.preventDefault();
      this.$snotify.async('Enviando comentario', 'Enviando', () =>
          new Promise((resolve, reject) => {
            Api.post(constants.endPoinds.addCommet, {
              credit_id: this.credit_id,
              comment: this.$refs.comment.value
            }, true)
                .then(() => {
                  this.pushComment();
                  resolve({
                    title: 'Aceptado !',
                    body: 'Comentario agregado correctamente',
                    timeout: 2000,
                    config: {
                      closeOnClick: true
                    }
                  });
                })
                .catch(() => {
                  reject({
                    title: 'Error !',
                    body: 'No se agrego el comentario intente nuevamente',
                    timeout: 2000,
                    config: {
                      closeOnClick: true
                    }
                  })
                });
          }));
    },
    pushComment() {
      this.data.push({
        analyst_name: auth.user.name,
        date: new Date(),
        comment: this.$refs.comment.value
      });
      this.$refs.comment.value = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/app';

.comments {
  .card-body {
    padding: 1rem;
  }

  .card-title {
    border-bottom: 1px solid $gray-600;
    padding-bottom: 1rem;
  }

  .scroll {
    max-height: 150px;
    overflow-y: auto;
    @include scroll-bar($sidebar-scrollbar-bg);
  }

  textarea {
    min-height: 100px;
  }

  .h-260 {
    min-height: 260px;
  }
}

.increased {
  textarea {
    min-height: 243px !important;
  }
}
</style>