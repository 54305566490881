<template>
  <b-navbar class="header d-print-none">
    <b-nav class="col-lg-3 col-sm-5 mr-1 row">
      <b-form @submit="search" class="d-sm-down-none w-100 ml-4" inline>
        <b-form-group class="w-100">
          <b-input-group class="input-group-no-border w-100">
            <div class="input-group-addon d-flex align-items-center">
              <i class="la la-search px-3"/>
            </div>
            <input @keyup="search" autocomplete="false" class="form-control" ref="search" id="search-input"
                   placeholder="Buscar"/>
          </b-input-group>
        </b-form-group>
      </b-form>
    </b-nav>
    <b-nav class="left-line col-lg-4 col-sm-5">
      <b-form class="d-sm-down-none w-100 ml-2" inline>
        <b-form-group class="w-100">
          <b-input-group class="input-group-no-border w-100">
            <div id="reportrange" ref="calendar" class="input-group-addon d-flex align-items-center piker">
              <i class="la la-calendar px-2"/>
            </div>
            <span @click="triggerCalendar" class="form-control">{{ showText }}</span>
            <div @click="triggerCalendar" class="input-group-addon d-flex align-items-center piker">
              <i class="la la-angle-down px-1" style="font-size: 13px"/>
            </div>
            <div class="input-group-addon d-flex align-items-center" @click="clearDateRange">
              <i class="la la-remove px-3" style="font-size: 15px"/>
            </div>
          </b-input-group>
        </b-form-group>
      </b-form>
    </b-nav>
    <b-nav>
      <a @click="downloadCsv" :disabled="downloadingReportCSV" class="p-2">

        <span v-if="downloadingReportCSV">
          <div class="loadingio-spinner-spinner-9499e4w6geq"><div class="ldio-by3s147xwqp">
  <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
</div></div>
        </span>
        <span v-else><i class="la la-download"></i></span>
      </a>
    </b-nav>
    <b-nav class="ml-auto">
      <b-nav-item-dropdown class="settingsDropdown d-sm-down-none" no-caret right>
        <template slot="button-content">
          <img class="img-thumbnail" :src="auth.user.company.image" :alt="auth.user.company.name">
        </template>
        <div class="col-lg-6 mw-100">
          <div class="row">
            <div class="col-sm-3">
              <img class="img-thumbnail img-fluid" :src="auth.user.company.image" :alt="auth.user.company.name">
            </div>
            <div class="col-sm-9">
              <h6 class="m-0 p-0">{{ auth.user.company.name }}</h6>
              <a @click="logout">
                <i class="la la-sign-out" style="font-size: 15px"/>
                Cerrar sesión
              </a>
            </div>
          </div>
        </div>
      </b-nav-item-dropdown>
    </b-nav>
  </b-navbar>
</template>

<script>

import {mapActions, mapState} from 'vuex';
import auth from "../../helpers/auth";
import moment from 'moment'
import {constants, serializer} from "../../helpers";
import {Api} from "../../helpers/api";
import $ from 'jquery'

require('daterangepicker');
require('jquery');

export default {
  name: 'Header',
  data: () => {
    return {
      auth: auth,
      showText: 'Sin filtro',
      start_date: null,
      end_date: null,
      downloadingReportCSV: false
    }
  },
  computed: {
    ...mapState('layout', {
      sidebarClose: state => state.sidebarClose,
      sidebarStatic: state => state.sidebarStatic,
    }),
    ...mapState('list_requests', {
      currentStatus: state => state.status
    }),
  },
  mounted: function () {
    moment.locale('es');
    $('#reportrange').daterangepicker({
      ranges: {
        'Hoy': [moment(), moment()],
        'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Últimos 7 dias': [moment().subtract(7, 'days'), moment()],
        'Ultimos 30 dias': [moment().subtract(1, 'month'), moment()],
        'Este mes': [moment().startOf('month'), moment()],
        'Trimestre hasta la fecha': [moment().subtract(3, 'month').startOf('month'), moment()]
      },
      showDropdowns: true,
      applyButtonClasses: 'btn btn-warning',
      cancelButtonClasses: 'btn btn-default',
      linkedCalendars: false,
      opens: 'right',
      "locale": {
        "format": "YYYY/MM/DD",
        "separator": " - ",
        "applyLabel": "Aplicar",
        "cancelLabel": "Cancelar",
        "fromLabel": "Desde",
        "toLabel": "Hasta",
        "customRangeLabel": "Personalizar",
        "weekLabel": "",
        "daysOfWeek": [
          "Do",
          "Lu",
          "Ma",
          "Mi",
          "Ju",
          "Vi",
          "Sa"
        ],
        "monthNames": [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Augosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Deciembre"
        ],
        "firstDay": 1
      },
    }).on('apply.daterangepicker', (evt, picker) => {
      this.start_date = picker.startDate;
      this.end_date = picker.endDate;
      let label = picker.chosenLabel;
      if (label === 'Personalizar') {
        this.showText = this.start_date.format('DD/MM/YYYY') + ' - ' + this.end_date.format('DD/MM/YYYY')
      } else {
        this.showText = label;
      }
      this.loadRequests({
        start_date: this.start_date.format('YYYY-MM-DD'),
        end_date: this.end_date.format('YYYY-MM-DD'),
        page: 1
      })
    })
        .on('cancel.daterangepicker', () => {
          this.clearDateRange()
        });
  },
  methods: {
    ...mapActions('layout', ['toggleSidebar', 'switchSidebar', 'changeSidebarActive']),
    ...mapActions('list_requests', ['loadRequests', 'clearDates', 'clearSearch']),
    toggleSidebarMethod() {
      if (this.sidebarStatic) {
        this.toggleSidebar();
        this.changeSidebarActive(null);
      } else {
        this.toggleSidebar();
        const paths = this.$route.fullPath.split('/');
        paths.pop();
        this.changeSidebarActive(paths.join('/'));
      }
    },
    logout() {
      auth.logout();
      this.$router.push('/login');
    },
    triggerCalendar() {
      this.$refs.calendar.click();
    },
    clearDateRange() {
      this.clearDates();
      this.showText = 'Sin filtro';
      this.loadRequests(false);
    },
    search(evt) {
      evt.preventDefault();
      let value = this.$refs.search.value;
      if (value.length > 2) {
        this.loadRequests({
          search: value
        })
      } else if (value.length === 0) {
        this.clearSearch();
        this.loadRequests({
          status: this.currentStatus
        });
      }
    },
    downloadCsv() {
      if (this.start_date != null && this.end_date != null) {
        this.downloadingReportCSV = true;
        Api.download(constants.endPoinds.exportAllCredits + serializer.get({
          status: this.currentStatus,
          start_date: this.start_date.format('YYYY-MM-DD'),
          end_date: this.end_date.format('YYYY-MM-DD')
        }),
        `report_${this.start_date.format('YYYY-MM-DD') + '_' + this.end_date.format('YYYY-MM-DD')}.xlsx`,
        true, function downloaded() {
          this.downloadingReportCSV = false;
        }.bind(this));



      } else {
        this.$snotify.info('Seleccione un rango de fechas para exportar', 'Atención !!');
      }
    }
  }
};
</script>
<style lang="scss">
.datepiker > .form-control {
  border: none;
  padding: 0;
}
</style>
<style type="text/css">
@keyframes ldio-by3s147xwqp {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

.ldio-by3s147xwqp div {
  left: 47px;
  top: 24px;
  position: absolute;
  animation: ldio-by3s147xwqp linear 1s infinite;
  background: #0a0a0a;
  width: 6px;
  height: 12px;
  border-radius: 3px / 6px;
  transform-origin: 3px 26px;
}

.ldio-by3s147xwqp div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
  background: #0a0a0a;
}

.ldio-by3s147xwqp div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
  background: #0a0a0a;
}

.ldio-by3s147xwqp div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
  background: #0a0a0a;
}

.ldio-by3s147xwqp div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
  background: #0a0a0a;
}

.ldio-by3s147xwqp div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
  background: #0a0a0a;
}

.ldio-by3s147xwqp div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
  background: #0a0a0a;
}

.ldio-by3s147xwqp div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
  background: #0a0a0a;
}

.ldio-by3s147xwqp div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
  background: #0a0a0a;
}

.ldio-by3s147xwqp div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
  background: #0a0a0a;
}

.ldio-by3s147xwqp div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
  background: #0a0a0a;
}

.ldio-by3s147xwqp div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
  background: #0a0a0a;
}

.ldio-by3s147xwqp div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #0a0a0a;
}

.loadingio-spinner-spinner-9499e4w6geq {
  width: 42px;
  height: 42px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.ldio-by3s147xwqp {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.42);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.ldio-by3s147xwqp div {
  box-sizing: content-box;
}

/* generated by https://loading.io/ */
</style>
<style src="./Header.scss" lang="scss" scoped/>
