<template>
  <div class="card docs">
    <div class="card-body">
      <h4 class="card-title text-center">
        Documentación
      </h4>
      <div class="scroll">
        <div v-for="(item, index) in data" v-bind:key="index">
          <button class="btn btn-sm btn-clear btn-block" v-b-toggle="'docs'+index.toString()">
            {{item.name}}
            <span v-if="item.files.length && item.files[0].url.split('.').pop().includes('pdf')"
                  @click.stop="openInBrowser(item)"
                 title="Abrir en nueva pestaña"
                 class="la la-expand bio-expand"/>
            <i class="la la-angle-down pull-right"></i>
          </button>
          <b-collapse accordion="accordion-docs" :id="'docs'+index.toString()" class="mt-2 pt-2 pb-2">
            <b-carousel v-if="item.files.length > 1"
                        controls
                        indicators>
              <b-carousel-slide v-for="(file, index) in item.files" v-bind:key="index" class="text-center">
                <object v-if="item.files[0].url.split('.').pop().includes('pdf')"
                        class="bio-pdf-viewer"
                        :data="item.files[0].url+'#view=FitH'"
                        type="application/pdf">
                  <embed :src="item.files[0].url+'#view=FitH'" type="application/pdf" />
                </object>
                <img v-else
                    slot="img"
                    :src="file.url"
                    :alt="item.name"
                />
              </b-carousel-slide>
            </b-carousel>
            <div v-else-if="item.files.length">
              <object v-if="item.files[0].url.split('.').pop().includes('pdf')"
                      class="bio-pdf-viewer"
                      :data="item.files[0].url+'#view=FitH'"
                      type="application/pdf">
                <embed :src="item.files[0].url+'#view=FitH'" type="application/pdf" />
              </object>
              <img
                      v-else
                      :src="item.files[0].url" :alt="item.name"/>
            </div>
            <div v-else class="pt-5 text-center">
              <i class="fa fa-file" style="font-size: 30px"></i>
              <h2>Sin Documentos</h2>
            </div>
          </b-collapse>

        </div>

        <div v-if="student.first_name != null && (auth.user.user.document_id == '1026585454')">
          <button class="btn btn-sm btn-clear btn-block btn-docs" v-b-toggle="'90'">
            <div>Foto de perfil del estudiante</div>
          </button>
          <b-collapse accordion="accordion" id="90" class="mt-2 pt-2 pb-2">
            <div class="pl-5">
              <img :src="student.profile_image"
                   class="img-fluid"/>
            </div>
          </b-collapse>
          <button class="btn btn-sm btn-clear btn-block btn-docs" v-b-toggle="'91'">
            <div>Documento foto frontal del estudiante</div>
          </button>
          <b-collapse accordion="accordion" id="91" class="mt-2 pt-2 pb-2">
            <div class="pl-5">
              <img :src="student.document_image_front"
                   class="img-fluid"/>
            </div>
          </b-collapse>
          <button class="btn btn-sm btn-clear btn-block btn-docs" v-b-toggle="'92'">
            <div>Documento foto reverso del estudiante</div>
          </button>
          <b-collapse accordion="accordion" id="92" class="mt-2 pt-2 pb-2">
            <div class="pl-5">
              <img :src="student.document_image_back"
                   class="img-fluid"/>
            </div>
          </b-collapse>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/helpers/auth";


  export default {
    name: "Documents",
    props: {
      data: {type: Array, default: null},
      student: {type: Array, default: () => ([])}
    },
    methods:{
      openInBrowser(item) {
        window.open(item.files[0].url,'_blank');
      }
    },
    data() {
      return {
        auth: auth
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../styles/app';

  .docs {
    .card-body {
      padding: 1rem;
    }
      .scroll{
          height: 450px;
          overflow-y: auto;
          overflow-x: hidden;
          @include scroll-bar($sidebar-scrollbar-bg);
      }
    .card-title {
      border-bottom: 1px solid $gray-600;
      padding-bottom: 1rem;
    }
    .img-profile {
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
    }
    .btn {
      background: #ffffff;
      text-align: left;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      font-size: larger;
      border-bottom: 1px solid $gray-200;
      color: $gray-600;
      i {
        color: $warning;
      }
    }
    h3 {
      color: $warning;
      font-weight: 500;
      font-size: 1.15rem;
      margin: 0;
    }
    img {
      margin: 0 auto;
      width: 50%;
    }
    .bio-pdf-viewer{
      width: 100%;
      height: 450px;
    }
    .bio-expand{
      color: $yellow;
    }
  }
</style>
