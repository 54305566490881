<template>
  <div class="form">
    <div class="card">
      <div class="card-body pl-4 pr-4">
        <h4 class="card-title text-center">
          Formulario
        </h4>
        <div class="scroll">
          <div v-for="(item, index) in data" v-bind:key="index">
            <button class="btn btn-sm btn-clear btn-block form-title-container" v-b-toggle="index.toString()">
              <img :src="item.image"/><div>{{item.name}}</div>
              <i class="la la-angle-down pull-right"></i>
            </button>
            <b-collapse accordion="accordion" :id="index.toString()" class="mt-2 pt-2 pb-2">
              <div class="pl-5" v-for="(field, index) in item.fields" v-bind:key="index">
                <h3>{{field.name}}</h3>
                <p>{{field.value}}</p>
              </div>
            </b-collapse>
          </div>

          <div v-if="student.first_name != null && (auth.user.company.id == 16 || auth.user.company.id == 90)">
            <button class="btn btn-sm btn-clear btn-block form-title-container" v-b-toggle="'94'">
              <img src="https://s3.amazonaws.com/app-biocredit/icons/Documentation/family.png"/><div>Información del Estudiante</div>
              <i class="la la-angle-down pull-right"></i>
            </button>
            <b-collapse accordion="accordion" id="94" class="mt-2 pt-2 pb-2">
              <div class="pl-5">
                <h3>Nombres</h3>
                <p>{{student.first_name}}</p>
                <h3>Apellidos</h3>
                <p>{{student.first_last_name}}</p>
                <h3>N° Documento</h3>
                <p>{{student.document_id}}</p>
                <h3>Celular</h3>
                <p>{{student.phone}}</p>
                <h3>Fecha de nacimiento</h3>
                <p>{{student.birthdate}}</p>
                <h3>E-mail</h3>
                <p>{{student.email}}</p>
              </div>
            </b-collapse>
          </div>

          <div v-if="collegeData.college != null && auth.user.company.id == 16">
            <button class="btn btn-sm btn-clear btn-block form-title-container" v-b-toggle="'95'">
              <img src="https://s3.amazonaws.com/app-biocredit/icons/Documentation/family.png"/><div>Información Teseo</div>
              <i class="la la-angle-down pull-right"></i>
            </button>
            <b-collapse accordion="accordion" id="95" class="mt-2 pt-2 pb-2">
              <div class="pl-5">
                <h3>Ciudad</h3>
                <p>{{collegeData.city}}</p>
                <h3>Dirección</h3>
                <p>{{collegeData.address}}</p>
                <h3>Programa Universitario</h3>
                <p>{{collegeData.program}}</p>
                <h3>Universidad</h3>
                <p>{{collegeData.college}}</p>
                <h3>Iniciales Universidad</h3>
                <p>{{collegeData.college_initials}}</p>
                <h3>NIT Univerdidad</h3>
                <p>{{collegeData.college_nit}}</p>
              </div>
            </b-collapse>
          </div>

          <div v-if="userCreditHistory.length > 1 ">
            <button class="btn btn-sm btn-clear btn-block form-title-container" v-b-toggle="'100'">
              <img src="https://s3.amazonaws.com/app-biocredit/icons/Documentation/document.png">
              <div>Historial de Créditos</div>
              <i class="la la-angle-down pull-right"></i>
            </button>
            <b-collapse accordion="accordion" id="100" class="mt-2 pt-2 pb-2">
              <div class="pl-5" v-for="(history, index) in userCreditHistory" v-bind:key="index">
                <div v-if="creditData.credit_id != history.credit_id">
                  <h3>Fecha de Creación: {{history.credit_created_at}}</h3>
                  <p style="margin-bottom: 0px;">Id: {{history.credit_id}}</p>
                  <p style="margin-bottom: 0px;">Estado: {{history.name}}</p>
                  <p style="margin-bottom: 0px;">Monto: $ {{ Intl.NumberFormat().format(history.total_amount) }}</p>
                  <p>No. Cuotas: {{history.fee_number}}</p>
                </div>
              </div>
            </b-collapse>
          </div>

          <div v-if="reportedUserEdupol != '' && (auth.user.company.id == 90 || auth.user.company.id == 16)">
            <button class="btn btn-sm btn-clear btn-block form-title-container" v-b-toggle="'101'">
              <img src="https://s3.amazonaws.com/app-biocredit/icons/Documentation/document.png"><div>Comportamiento de Pago</div>
              <i class="la la-angle-down pull-right"></i>
            </button>
            <b-collapse accordion="accordion" id="101" class="mt-2 pt-2 pb-2">
              <div class="pl-5">
                <h3>Número de cuotas: </h3>
                <p>{{reportedUserEdupol.fee_number}}</p>
                <h3>Pagadas: </h3>
                <p>{{reportedUserEdupol.paid}}</p>
                <h3>En Mora: </h3>
                <p>{{reportedUserEdupol.in_debt}}</p>
                <h3>Por Facturar: </h3>
                <p>{{reportedUserEdupol.by_bill}}</p>
                <h3>Dias Vencidos: </h3>
                <p>{{reportedUserEdupol.days_overdue}}</p>
              </div>
            </b-collapse>
          </div>

          <div v-if="riskCentral != '' && (auth.user.company.id == 16)">
            <button class="btn btn-sm btn-clear btn-block form-title-container" v-b-toggle="'102'">
              <img src="https://s3.amazonaws.com/app-biocredit/icons/Documentation/family.png"/><div>Centrales de riesgo</div>
              <i class="la la-angle-down pull-right"></i>
            </button>
            <b-collapse accordion="accordion" id="102" class="mt-2 pt-2 pb-2">
              <div class="pl-5">
                <h3>Decisión</h3>
                <p>{{riskCentral.decision}}</p>
                <h3>Causal</h3>
                <p>{{riskCentral.causal}}</p>
                <h3>Cupo definitivo</h3>
                <p>{{riskCentral.definitive_quota}}</p>
                <h3>Acierta</h3>
                <p>{{riskCentral.acierta}}</p>
                <h3>No. pagare</h3>
                <p>{{riskCentral.nropagare}}</p>
              </div>
            </b-collapse>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/helpers/auth";

  export default {
    name: "Form",
    props: {
      data: {type: Array, default: () => ([])},
      student: {type: Array, default: () => ([])},
      userCreditHistory: {type: Array, default: () => ([])},
      collegeData: {type: Object, default: () => ([])},
      reportedUserEdupol: {type: Object, default: () => ([])},
      creditData: {type: Object, default: () => ([])},
      riskCentral: {type: Object, default: () => ([])}
    },
    data() {
      return {
        auth: auth
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../styles/app';

  .form {
    //height: 400px;

    .card-body {
      padding: 1rem;
    }
    .scroll{
      height: 450px;
      overflow-y: auto;
      overflow-x: hidden;
      @include scroll-bar($sidebar-scrollbar-bg);
    }
    .card-title {
      border-bottom: 1px solid $gray-600;
      padding-bottom: 1rem;
    }
    .img-profile {
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
    }
    .btn {
      background: #ffffff;
      text-align: left;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      font-size: larger;
      border-bottom: 1px solid $gray-200;
      color: $gray-600;
      i {
        color: $warning;
      }
    }
    h3 {
      color: $warning;
      font-weight: 500;
      font-size: 1.15rem;
      margin: 0;
    }
    img {
      width: 20px;
      margin-right: 10px;
    }
  }
</style>
