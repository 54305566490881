import {Api} from "./api";
import {constants} from "./constants";

export default {
  user: {
    access_token: null,
    company: {},
    reports: []
  },

  init() {
    this.user = JSON.parse(localStorage.getItem('user'));
  },

  accessParam() {
    return this.user.access_token;
  },

  isLogin() {
    return this.user != null;
  },

  setUser(user) {
    this.user = user;
    localStorage.setItem('user', JSON.stringify(user));
  },
  login(mail, password) {
    return new Promise((resolve, reject) => {
      Api.post(constants.endPoinds.login, {
        email: mail,
        password: password
      }).then(value => {
        this.setUser(value.data);
        resolve(value)
      }).catch(err => {
        reject(err)
      })
    });
  },

  logout() {
    this.user = null;
    localStorage.clear();
  },
}
;