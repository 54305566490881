<template>
    <div class="list-requests col-lg-12 pr-0"
         :infinite-scroll-disabled="isLoading" infinite-scroll-immediate-check="false"
         infinite-scroll-throttle-delay="5000"
         v-infinite-scroll="load" infinite-scroll-distance="0">
        <div class="head-list" v-if="subListRequests.today.length">
            HOY
        </div>
        <ul>
            <li v-for="(item, index) in subListRequests.today" v-bind:key="index" class="p-2 pl-4"
                @click="loadItemDetail(item, {id: item.id, list: 'today', index: index})"
                :class="{active: isActive(item.id)}">
                <span v-if="item.display_state" class="badge badge-warning">&nbsp;</span>
                <p class="pull-right">{{formatHour(item.created_at)}}</p>
                <h6>{{item.name}}</h6>
                <p v-if="item.original_total_amount">{{item.original_total_amount}}</p>
                <p>{{item.credit_line}}</p>
                <p>{{item.company_name}}</p>
            </li>
        </ul>
        <div class="head-list" v-if="subListRequests.yesterday.length">
            AYER
        </div>
        <ul>
            <li v-for="(item, index) in subListRequests.yesterday" v-bind:key="index" class="p-2 pl-4"
                @click="loadItemDetail(item, {id: item.id, list: 'yesterday', index: index})"
                :class="{active: isActive(item.id)}">
                <span v-if="item.display_state" class="badge badge-warning">&nbsp;</span>
                <p class="pull-right">{{formatHour(item.created_at)}}</p>
                <h6>{{item.name}}</h6>
                <p v-if="item.original_total_amount">{{item.original_total_amount}}</p>
                <p>{{item.credit_line}}</p>
                <p>{{item.company_name}}</p>
            </li>
        </ul>
        <div class="head-list" v-if="subListRequests.week.length">
            ESTA SEMANA
        </div>
        <ul>
            <li v-for="(item, index) in subListRequests.week" v-bind:key="index" class="p-2 pl-4"
                @click="loadItemDetail(item, {id: item.id, list: 'week', index: index})"
                :class="{active: isActive(item.id)}">
                <span v-if="item.display_state" class="badge badge-warning">&nbsp;</span>
                <p class="pull-right">{{formatWeek(item.created_at)}}</p>
                <h6>{{item.name}}</h6>
                <p v-if="item.original_total_amount">{{item.original_total_amount}}</p>
                <p>{{item.credit_line}}</p>
                <p>{{item.company_name}}</p>
            </li>
        </ul>
        <div class="head-list" v-if="subListRequests.month.length">
            ESTE MES
        </div>
        <ul>
            <li v-for="(item, index) in subListRequests.month" v-bind:key="index" class="p-2 pl-4"
                @click="loadItemDetail(item, {id: item.id, list: 'month', index: index})"
                :class="{active: isActive(item.id)}">
                <span v-if="item.display_state" class="badge badge-warning">&nbsp;</span>
                <p class="pull-right">{{formatMonth(item.created_at)}}</p>
                <h6>{{item.name}}</h6>
                <p v-if="item.original_total_amount">{{item.original_total_amount}}</p>
                <p>{{item.credit_line}}</p>
                <p>{{item.company_name}}</p>
            </li>
        </ul>
        <div class="head-list" v-if="subListRequests.rest.length">
            HACE MAS DE UN MES
        </div>
        <ul>
            <li v-for="(item, index) in subListRequests.rest" v-bind:key="index" class="p-2 pl-4"
                @click="loadItemDetail(item, {id: item.id, list: 'rest', index: index})"
                :class="{active: isActive(item.id)}">
                <span v-if="item.display_state" class="badge badge-warning">&nbsp;</span>
                <p class="pull-right">{{formatFull(item.created_at)}}</p>
                <h6>{{item.name}}</h6>
                <p v-if="item.original_total_amount">{{item.original_total_amount}}</p>
                <p>{{item.credit_line}}</p>
                <p>{{item.company_name}}</p>
            </li>
        </ul>
        <div class="loading text-center p-3" v-if="!isLoadingEnd">
            <i class="fa fa-3x fa-spinner fa-spin"></i>
            <h3>Cargando ...</h3>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapState} from 'vuex';
    import moment from 'moment'
    import $ from 'jquery'

    export default {
        name: 'ListRequests',
        computed: {
            ...mapState('list_requests', {
                subListRequests: state => state.subListRequests,
                isLoadingEnd: state => state.isLoadingEnd,
                status: state => state.status,
                currentCreditId: state => state.credit_id,
                isLoading: state => state.isLoading
            }),
        },
        created: function () {
            moment.locale('es');
            this.loadBadges();
        },
        methods: {
            ...mapActions('list_requests', ['nextPage', 'loadRequests', 'loadDetail', 'loadBadges']),
            formatHour: function (date) {
                return moment(date).format('hh:mm a');
            },
            formatWeek: function (date) {
                return moment(date).format('dddd hh:mm a')
            },
            formatMonth: function (date) {
                return moment(date).format('MMMM DD hh:mm a')
            },
            formatFull: function (date) {
                return moment(date).format('YYYY/MM/DD hh:mm a');
            },
            isActive(id) {
                return this.currentCreditId === id;
            },
            load() {
                if (!this.isLoading) {
                    this.nextPage();
                }
            },
            loadItemDetail(item, params) {
                item.display_state = 0;
                this.loadDetail(params).then(() => {
                    this.loadBadges();
                })
            }
        },
        watch: {
            status: {
                handler: function () {
                    $('.list-requests').scrollTop(0);
                }
            }
        }
    };
</script>

<style src="./ListRequests.scss" lang="scss" scoped/>
