export const constants = {
    baseUrl: 'https://app.bio.credit/api/v1/analyst',

    endPoinds: {
        login: '/login',
        resetPassword: '/reset-password',
        testToken: '/login-test',
        listCredits: '/credits',
        creditDetails: '/credit-detail',
        changeStatus: '/change-credit-status',
        addCommet: '/analyst-credit-comment',
        exportCredits: '/export-credits',
        exportAllCredits: '/export-all-credits',
        exportPdfDetail: '/export-detail-credit',
        creditCounter: '/credits-counter',
        creditApproveWithAmount: '/change-credit-status-data',
        notifyTeseo: '/notify-teseo',
        cancelCredit: '/cancel-credit',
        reNotifyAll: '/re-send-all-notify',
        createQrTransaction: '/create-qr-transaction',
        validateQrTransaction: '/validate-qr-transaction',
        saveInvoiceTransaction: '/upload-invoice-transaction',
        getDataGraphics: '/graphics',
        filterDataGraphics: '/filter-graphics',
        verifyDocument: '/verify-document',
        paymentBehavior: '/payment-behavior',
    },
    creditsStatus: {
        incoming: 1,
        approved: 2,
        denied: 3,
        inProcess: 4,
        returned: 7,
        pendingNatura: 15,
        cancelled: 16,
        askedForCall: 17,
        whatsapp: 18,
        approved_50: 21,
        approved50: 22,
    }
};
