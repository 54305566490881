<template>
  <div class="comments">
    <div class="card h-100">
      <div class="card-body pl-4 pr-4">
        <h4 class="card-title text-center">
          Razón de Rechazo
        </h4>
        <div class="scroll">
          <div class="item" v-for="(item, index) in data" v-bind:key="index">
            <p class="m-0">{{ item.reason }}</p>
            <small class="text-muted">{{ format(item.create_at) }}</small>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import moment from 'moment'
import auth from "../../../helpers/auth";


export default {
  name: "ReasonRejection",
  props: {
    data: {
      type: Array, default: null
    }
  },
  computed: {
    ...mapState('list_requests', {
      creditDetails: state => state.itemDetail,
      credit_id: state => state.credit_id
    })
  },
  data: () => {
    return {
      auth: auth,
    }
  },
  methods: {
    ...mapActions('list_requests', ['nextPage', 'loadRequests', 'loadDetail', 'setDetail', 'addComment']),
    format(date) {
      return moment(date).format('YYYY/MM/DD hh:ss a')
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/app';

.comments {
  .card-body {
    padding: 1rem;
  }

  .card-title {
    border-bottom: 1px solid $gray-600;
    padding-bottom: 1rem;
  }

  .scroll {
    max-height: 150px;
    overflow-y: auto;
    @include scroll-bar($sidebar-scrollbar-bg);
  }

  textarea {
    min-height: 100px;
  }

  .h-260 {
    min-height: 260px;
  }
}

.increased {
  textarea {
    min-height: 243px !important;
  }
}
</style>