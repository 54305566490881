<template>
  <router-view/>
</template>

<script>
  import auth from "./helpers/auth";
  import {mapState} from 'vuex';

  export default {
    name: 'App',
    computed: {
      ...mapState('list_requests', {
        goLogin: state => state.goLogin,
      })
    },
    created() {
      auth.init();
      if (!auth.isLogin()) {
        this.$router.push('/login');
      }
      const currentPath = this.$router.history.current.path;
      if (currentPath === '/' || currentPath === '/app') {
        this.$router.push('/app');
      }
    },
    watch: {
      goLogin: {
        handler: function () {
          this.$router.push('/login');
        }
      }
    }
  };
</script>

<style src="./styles/theme.scss" lang="scss"/>
