<template>
  <div class="card natura_region">
    <div class="card-body">
      <h4 class="card-title text-center">
        Gerente asignado
      </h4>

      <div class="pt-1 pl-4 row">
        <div class="col-6 p-3">
          <h3>Tipo de gerente</h3>
          <p>{{ data.manager }}</p>
          <h3>Tipo de sector</h3>
          <p>{{ data.sector }}</p>
        </div>
        <div class="col-6 p-3">
          <h3>Nombre del gerente</h3>
          <p>{{ data.name }}</p>
          <h3>Correo del gerente</h3>
          <p>{{ data.email }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "NaturaRegion",
    props: {
      data: {type: Object, default: {
              manager_type: "demo",
              manager_sector : "demo2",
              manager_name: "das",
              manager_email : "asdd"
          }}
    },
  }
</script>

<style lang="scss" scoped>
  @import '../../../styles/app';

  .natura_region {
    .card-body {
      padding: 1rem;
    }
    .card-title {
      border-bottom: 1px solid $gray-600;
      padding-bottom: 1rem;
    }
    hr {
      margin: 0;
    }
    h3 {
      color: $warning;
      font-weight: 500;
      font-size: 1.15rem;
      margin: 0;
    }
    p{
      padding-top: 5px;
      font-size: 1.3rem;
    }
  }
</style>