<template>
  <div class="comments">
    <div class="card h-260">
      <div class="card-body pl-4 pr-4" v-if="auth.user.user.core_role_id != 7">
        <h4 class="card-title text-center">
          Monto a utilizar
        </h4>

<!--        <h5><span style="color: #ff9800;">Monto Disponible:</span><b> {{ data.available_amount }}</b></h5>-->

        <div class="form-group">
          <label for="amount">Valor de Compra</label>
          <input id="amount" name="amount" ref="amount" type="number" placeholder="Ingrese el monto"
                 class="form-control">
        </div>
        <div class="text-right">
          <button class="btn btn-success pull-right" type="submit" v-on:click="transactionModal()"> Utilizar</button>
        </div>

      </div>
    </div>

    <b-modal
        id="modalTransactions"
        ref="modal_transaction_amount" centered
        hide-footer
        hide-header
        size="natura">
      <div class="form-group" v-if="!this.responseValidateQr.status">
        <div class="natura-title">
          <div class="natura-title-text">
            Escanee el código
          </div>
        </div>
        <div v-if="qrData" class="">
          <img :src="qrData.url_qr"
               class="img-profile img-fluid mx-auto d-block" style="width: 50%;"/>
        </div>
      </div>
      <div class="form-group" v-if="this.responseValidateQr.status">
        <div>
          <img :src="responseValidateQr.data.image"
               class="img-profile img-fluid mx-auto d-block"/>
          <br>
          <h4 style="text-align: center;">{{responseValidateQr.data.message}}</h4>
        </div>

      </div>

    </b-modal>

  </div>

</template>

<script>
import {mapState, mapActions} from 'vuex';
import moment from 'moment'
import {constants} from "../../../helpers";
import {Api} from "../../../helpers/api";
import auth from "../../../helpers/auth";

export default {
  name: "Transactions",
  props: {
    data: {type: Array, _default: []}
  },
  computed: {
    ...mapState('list_requests', {
      creditDetails: state => state.itemDetail,
      credit_id: state => state.credit_id
    })
  },
  data: () => {
    return {
      auth: auth,
      socketAddress: "https://dev.bio.credit",
      socketPort: ":8443",
      validateQrData: [],
      qrData: [],
      responseValidateQr: []
    }
  },
  methods: {
    ...mapActions('list_requests', ['nextPage', 'loadRequests', 'loadDetail', 'setDetail', 'addComment']),
    format(date) {
      return moment(date).format('YYYY/MM/DD hh:ss a')
    },
    createQRTransaction() {

      this.responseValidateQr = [];
      this.$snotify.async('Creando QR', 'Enviando', () =>
          new Promise((resolve, reject) => {
            Api.post(constants.endPoinds.createQrTransaction, {
              credit_id: this.credit_id,
              amount: this.$refs.amount.value
            }, true)
                .then((data) => {

                  this.qrData = data.data.data;
                  //console.log(this.qrData);
                  let hash = this.qrData.hash;
                  this.$refs.modal_transaction_amount.show();

                  this.validateQr(hash);

                  resolve({
                    title: 'Exitoso !',
                    body: 'Qr creado correctamente',
                    timeout: 2000,
                    config: {
                      closeOnClick: true
                    }
                  });
                })
                .catch((error) => {
                  //console.log(error);
                  reject({
                    title: 'Error !',
                    body: 'No se creo el QR',
                    error: error,
                    timeout: 2000,
                    config: {
                      closeOnClick: true
                    }
                  })
                });
          }));
    },
    validateQr(hash) {

      this.validateInterval = setInterval(function(){
        //console.log(this.qrData.hash);
        new Promise(() => {

              Api.get(constants.endPoinds.validateQrTransaction + '?hash=' + hash, {
              }, true)
                  .then((response) => {

                    this.validateQrData = response.data;
                    //console.log('validateQr', response, hash);

                    if(this.validateQrData.status) {
                      this.responseValidateQr = this.validateQrData;
                      this.validateQrData = '';
                      this.data.available_amount = this.responseValidateQr.data.available_amount;
                      this.$refs.amount.value = '';
                      clearInterval(this.validateInterval);
                      //console.log('ya leido');
                    } else {
                      //console.log('sin leer');
                    }
                  });

        });

      }.bind(this), 4000);

    },
    transactionModal() {
      clearInterval(this.validateInterval);
      if (this.$refs.amount.value === 0 || this.$refs.amount.value === '') {
        this.$snotify.async('Creando QR', 'Enviando', () =>
            new Promise((reject) => {
              reject({
                title: 'Importante !',
                body: 'EL valor no debe ser cero o vacio',
                timeout: 2000,
                config: {
                  closeOnClick: true
                }
              });
            }));
      } else if (this.$refs.amount.value > this.data.available_value_amount) {
        this.$snotify.async('Error QR', 'Enviando', () =>
            new Promise((reject) => {
              reject({
                title: 'Importante !',
                body: 'El monto no debe ser mayor que el disponible',
                timeout: 2000,
                config: {
                  closeOnClick: true
                }
              });
            }));
      } else {
        this.createQRTransaction();
      }
    }
  },
  destroyed() {
    clearInterval(this.validateInterval);
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent) => {
      if(bvEvent.type === 'hide') {
        // console.log('Modal is about to be hide', bvEvent, modalId);
        clearInterval(this.validateInterval);
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/app';

.comments {
  .card-body {
    padding: 1rem;
  }

  .card-title {
    border-bottom: 1px solid $gray-600;
    padding-bottom: 1rem;
  }

  .scroll {
    max-height: 150px;
    overflow-y: auto;
    @include scroll-bar($sidebar-scrollbar-bg);
  }

  textarea {
    min-height: 100px;
  }

  .h-260 {
    min-height: 260px;
  }
}

.increased {
  textarea {
    min-height: 243px !important;
  }
}
</style>