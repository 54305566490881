<template>
  <nav :class="{sidebar: true, sidebarStatic, sidebarOpened}">
    <header class="logo">
      <router-link to="/app">
        <img v-if="sidebarOpened || sidebarStatic" src="../../assets/biocredit-imagotipo.png"/>
        <span v-if="(sidebarOpened || sidebarStatic) && (![94, 101, 96, 103, 125, 126, 127, 128].includes(auth.user.company.id))">ANALISTAS</span>
        <span v-if="(sidebarOpened || sidebarStatic) && ([94, 101, 96, 103, 125, 126, 127, 128].includes(auth.user.company.id))">COMERCIOS</span>

        <img v-if="!sidebarOpened && !sidebarStatic" src="../../assets/biocredit-isotipo.png" class="isotipe"/>
      </router-link>
    </header>
    <ul class="nav">
      <NavLink
          header="Solicitudes"
          iconName="fa fa-bars"
          method="toggleSidebar"
          class-name="principal"
      />
      <NavLink
          v-if="![94, 101, 96, 103, 113, 105, 125, 126, 127, 128].includes(auth.user.company.id)"
          header="Entrantes"
          iconName="fa fa-inbox"
          index="Entrantes"
          method="loadRequests"
          :params="{status: constants.creditsStatus.incoming, page: 1}"
          :badge="badges.incoming"
      />
      <NavLink
          v-if="![94, 101, 96, 103, 113, 105, 93, 125, 126, 127, 128].includes(auth.user.company.id)"
          header="En Proceso"
          iconName="la la-exclamation-circle"
          index="En proceso"
          color="text-primary"
          method="loadRequests"
          :params="{status: constants.creditsStatus.inProcess, page: 1}"
      />
      <NavLink
          v-if="auth.user.company.id == 64"
          header="Devueltos"
          iconName="la la-minus-circle"
          index="Devueltos"
          color="text-warning"
          method="loadRequests"
          :params="{status: constants.creditsStatus.returned, page: 1}"
          :badge="badges.returned"
      />
      <NavLink
          header="Aprobados"
          iconName="la la-check-circle-o"
          index="Aprobados"
          color="text-success"
          method="loadRequests"
          :params="{status: constants.creditsStatus.approved, page: 1}"
          :badge="badges.approved"
      />
      <NavLink
          v-if="![94, 101, 96, 103, 113, 125, 126, 127, 128].includes(auth.user.company.id)"
          header="Rechazados"
          iconName="la la-times-circle"
          index="Rechazados"
          color="text-danger"
          method="loadRequests"
          :params="{status: constants.creditsStatus.denied, page: 1}"
          :badge="badges.denied"
      />
      <NavLink v-if="auth.user.company.id == 54"
               header="Pendientes"
               iconName="la la-exclamation-circle"
               index="Pendientes"
               color="text-primary"
               method="loadRequests"
               :params="{status: constants.creditsStatus.pendingNatura, page: 1}"
               :badge="badges.pendingNatura"/>
      <NavLink
          v-if="auth.user.company.id == 64"
          header="Cancelados"
          iconName="la la-ban"
          index="Cancelados"
          color="text-danger"
          method="loadRequests"
          :params="{status: constants.creditsStatus.cancelled, page: 1}"
          :badge="badges.cancelled"
      />
      <NavLink
          v-if="auth.user.user.core_role_id == 6 || auth.user.user.core_role_id == 1"
          v-show="false"
          header="Whatsapp"
          iconName="la la-whatsapp"
          index="Whatsapp"
          color="text-success"
          method="loadRequests"
          :params="{status: constants.creditsStatus.whatsapp, page: 1}"
          :badge="badges.whatsapp"
      />
      <NavLink
          v-if="auth.user.user.core_role_id == 6 || auth.user.user.core_role_id == 1"
          v-show="false"
          header="Llamada"
          iconName="la la-phone"
          index="Llamada"
          method="loadRequests"
          :params="{status: constants.creditsStatus.askedForCall, page: 1}"
          :badge="badges.askedForCall"
      />
      <NavLink
          v-if="auth.user.user.core_role_id == 7 || auth.user.user.core_role_id == 1 || (auth.user.user.core_role_id == 2 && auth.user.company.id == 90)"
          v-show="true"
          header="Aprobados al 50%"
          iconName="la la-check-circle-o"
          color="text-warning"
          index="APROBADOS AL 50%"
          method="loadRequests"
          :params="{status: constants.creditsStatus.approved_50, status1:constants.creditsStatus.approved50, page: 1}"
          :badge="badges.approved50"
      />
      <NavLink
          v-if="![94, 101, 96, 103, 113, 105, 93, 125, 126, 127, 128].includes(auth.user.company.id)"
          header="Graficas"
          iconName="fa fa-bar-chart"
          index="Graficas"
          class-name="principal"
          method="changeGraphics"
      />
      <NavLink
          v-if="auth.user.user.document_id == '1026585454'"
          header="Reportes"
          iconName="fa fa-plus"
          index="Reportes"
          class-name="principal"
          method="changeReports"
      />
      <!--      <NavLink
                v-if="auth.user.company.id == 94"
                header="Crear QR"
                iconName="fa fa-qrcode"
                index="Crear QR"
                class-name="principal"
                method="changeQrCode"
            />-->
    </ul>
  </nav>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import isScreen from '@/core/screenHelper';
import NavLink from './NavLink/NavLink';
import auth from "../../helpers/auth";
import {constants} from "../../helpers";

require('daterangepicker');
require('jquery');

export default {
  name: 'Sidebar',
  components: {NavLink},
  data: () => {
    return {
      auth: auth,
      constants: constants
    }
  },
  created: function () {
    // `this` hace referencia a la instancia vm
    console.log('auth: ', this.auth.user.user, this.auth.user.user.document_id);
  },
  methods: {
    ...mapActions('layout', ['changeSidebarActive', 'switchSidebar', 'toggleSidebar']),

    toggleSidebarMethod() {
      if (this.sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
        this.toggleSidebar();
      } else {
        this.switchSidebar();
      }
    }
  },
  computed: {
    ...mapState('layout', {
      sidebarStatic: state => state.sidebarStatic,
      sidebarOpened: state => !state.sidebarClose,
    }),
    ...mapState('list_requests', {
      badges: state => state.badges
    })
  },
};
</script>
<style src="./Sidebar.scss" lang="scss" scoped/>
