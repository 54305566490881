<template>
  <div style="padding: 10px;">

    <h1>Qr Creator</h1>

    <form >
      <div class="row section-background">

        <div class="col-md-6 col-xs-12 form-group form-group-lg"
             >
          <input type="text" class="form-control form__input" v-model="product.product_name"
                 placeholder="Nombre del producto">


        </div>

      </div>
    </form>

  </div>
</template>

<script>

import auth from "../../helpers/auth";

require('jquery');

export default {
  name: 'QrCode',
  data: () => {
    return {
      auth: auth,
      products: [],
      currentProduct: {},
      product: {
        product_images: [],
        product_description: '',
        product_name: '',
        product_price: 0,
        qr_image: "https://dev-biocredit.s3.us-west-2.amazonaws.com/products/qr/5d91b671.png",
        category: '',
        deleting: false
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '$ ',
        suffix: '',
        precision: 0,
        masked: false
      },
      productCategories: [],
      currentImage: "",
      config: {
        info: true,
        size: 1,
        outputType: 'jpeg',
        canScale: false,
        autoCrop: true,
        autoCropWidth: 720,
        autoCropHeight: 360,
        fixed: true,
        fixedNumber: [18, 9]
      },
      uploading: false,
      maxDescriptionLength: 400,
      currentTab: 1,
      totalTabs: 2,
      loadingQr: false,
      server: '',
      apiPath: "/api/v1/",
      currentGalleryImage: null,
      showLogout: false
    }
  },
  components: {
  },
  mounted: function () {
  },

  methods: {
  }
};
</script>
<style src="./QrCode.scss" lang="scss" scoped/>
