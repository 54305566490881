<template>
  <div class="card score">
    <div class="card-body">
      <h4 class="card-title text-center">
        {{ data.title }}
      </h4>

      <div v-for="list in data.lists" class="pt-1 pl-4">
        <div>
          <h4>{{ list.name }}: <b-badge :variant='list.status ? "success" : "danger"'>{{ list.status ? "Aprobada" : "No Aprobada" }}</b-badge></h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "Score",
    props: {
      data: {type: Object, default: null}
    },
  }
</script>

<style lang="scss" scoped>
  @import '../../../styles/app';

  .score {
    .card-body {
      padding: 1rem;
    }
    .card-title {
      border-bottom: 1px solid $gray-600;
      padding-bottom: 1rem;
    }
    hr {
      margin: 0;
    }
  }

  .increased {
    min-height: 260px;
    }
</style>