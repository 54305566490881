<template>
  <div style="padding: 10px;">
    <!--        <div v-for="(graphic, index) in auth.user.reports">
                <div class="p-2">
                    <h4 class="text-warning">{{graphic.title}}</h4>
                    <p class="p-0 m-0">{{graphic.description}}</p>
                </div>
    &lt;!&ndash;            <iframe :src="graphic.script" class="iframe-tablo"></iframe>&ndash;&gt;
                &lt;!&ndash;            <div :id="'graphic_'+index"></div>&ndash;&gt;
            </div>-->

    <b-card no-body>
      <b-tabs pills card>
        <b-tab title="Cantidad de Solicitudes" active>

          <b-form @submit="filterData">
            <label class="mt-3" for="dateFrom">Filtro desde:</label>
            <input type="date" id="dateFrom" name="trip-start"
                   v-model="dateFrom"
                   min="2019-01-01" max="2024-12-31" required>

            <label class="mt-3" for="dateTo">Filtro hasta:</label>
            <input type="date" id="dateTo" name="trip-start"
                   v-model="dateTo"
                   min="2019-01-01" max="2024-12-31" required>


            <b-button type="submit" variant="success">Filtrar</b-button>
            <b-button v-on:click="initGraphics" variant="danger">Limpiar</b-button>
          </b-form>

          <GChart
              type="BarChart"
              :data="chartData"
              :options="chartBarOptions"
              style="width: 100%; height: auto; margin: 0px; padding: 0px;"
          />
        </b-tab>
        <b-tab title="Estado de Aprobaciones">

          <b-form @submit="filterData">
            <label class="mt-3" for="dateFrom">Filtro desde:</label>
            <input type="date" id="dateFrom" name="trip-start"
                   v-model="dateFrom"
                   min="2019-01-01" max="2024-12-31" required>

            <label class="mt-3" for="dateTo">Filtro hasta:</label>
            <input type="date" id="dateTo" name="trip-start"
                   v-model="dateTo"
                   min="2019-01-01" max="2024-12-31" required>


            <b-button type="submit" variant="success">Filtrar</b-button>
            <b-button v-on:click="initGraphics" variant="danger">Limpiar</b-button>

          </b-form>

          <GChart
              type="PieChart"
              :data="pieChartData"
              :options="chartPieOptions"
          />
        </b-tab>
      </b-tabs>
    </b-card>


  </div>
</template>

<script>

import auth from "../../helpers/auth";
import { GChart } from 'vue-google-charts'

import {Api, constants} from "@/helpers";

require('jquery');

export default {
  name: 'Graphics',
  data: () => {
    return {
      auth: auth,

      chartData: [],
      pieChartData: [],
      chartBarOptions: {
        height: 600,
        bars: 'horizontal', // Required for Material Bar Charts.
        colors: ['#d95f02', '#1a51ff'],
        title: 'Cantidad de solicitudes por día'
      },
      chartPieOptions: {
        height: 600,
        colors: ['#d95f02', '#1a51ff'],
        title: 'Estados de aprobación de créditos'
      },
      dateFrom: '',
      dateTo: ''
    }
  },
  components: {
    GChart
  },
  mounted: function () {
    this.initGraphics();
  },

  methods: {
    triggerCalendar() {
      this.$refs.calendar.click();
    },
    initGraphics() {
      new Promise(() => {

        Api.get(constants.endPoinds.getDataGraphics, {}, true)
            .then((response) => {

              this.dateFrom = '';
              this.dateTo = '';

              //console.log('initGraphics', response);

              this.chartData = response.data.qtyRequests;
              this.pieChartData = response.data.statusRequests;
            });

      });
    },
    filterData(evt) {
      evt.preventDefault();

      var dateFrom = new Date(this.dateFrom);
      var dateTo = new Date(this.dateTo);

      //console.log(this.dateFrom, this.dateTo);

      if(this.dateFrom !== '' && this.dateTo !== '') {

        var diff = dateTo - dateFrom;
        //console.log('diferencia: ' + diff);

        if(diff >= 0) {
          new Promise(() => {

            Api.get(constants.endPoinds.getDataGraphics + '?dateFrom=' + this.dateFrom + '&dateTo=' + this.dateTo, {}, true)
                .then((response) => {

                  //console.log('initGraphics', response);

                  this.chartData = response.data.qtyRequests;
                  this.pieChartData = response.data.statusRequests;

                });

          });
        } else {
          this.$snotify.info('La fecha HASTA debe ser mayor', 'Atención !!');
        }


      } else {
        this.$snotify.info('La fechas deben estar llenas', 'Atención !!');
      }



    }
  }
};
</script>
<style src="./Graphics.scss" lang="scss" scoped/>
