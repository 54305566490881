import {constants} from "../helpers";

import axios from 'axios';
import auth from "./auth";

let cancel = [];

axios.default.defaults.baseURL = constants.baseUrl;

export const Api = {
    get,
    post,
    download,
    postFile
};

function get(endpoint, login = false) {
    return new Promise((resolve, reject) => {
        let endUrl = endpoint.split('?')[0];
        let index = cancel.findIndex((item) => {
            return item.endpoint === endUrl;
        });

        if (index > -1) {
            let reqCancel = cancel.splice(index, 1);
            reqCancel[0].source.cancel();
        }

        let request = {
            endpoint: endUrl,
            source: axios.CancelToken.source()
        };
        cancel.push(request);

        let headers = {'Content-Type': 'application/json'};
        if (login) {
            headers['Authorization'] = `Bearer ${auth.accessParam()}`
        }
        axios.get(`${constants.baseUrl}${endpoint}`, {
            headers: headers,
            cancelToken: request.source.token
        }).then(value => {
            resolve(value)
        }).catch(err => {
            if (!axios.isCancel()) {
                reject(err);
            }
        });
    })
}

function download(endpoint, filename, login, callback = null) {
    let xhr = new XMLHttpRequest();
    xhr.open('get', `${constants.baseUrl}${endpoint}`, true);
    if (login)
        xhr.setRequestHeader("Authorization", `Bearer ${auth.accessParam()}`);
    xhr.responseType = 'arraybuffer';

    xhr.onload = function () {
        if (this.status === 200) {
            let blob = new Blob([this.response], {type: this.getResponseHeader('content-type')});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
            if (callback != null) {
                callback()
            }
        } else {
            if (callback != null) {
                callback()
            }
        }
    };
    xhr.send()
}

function post(endpoint, body, login = false) {
    let headers = {'Content-Type': 'application/json'};
    if (login) {
        headers['Authorization'] = `Bearer ${auth.accessParam()}`;
    }
    return axios.post(`${endpoint}`, JSON.stringify(body), {
        headers: headers
    })
}

function postFile(endpoint, body, login = false) {
    let headers = {'Content-Type': 'multipart/form-data'};
    if (login) {
        headers['Authorization'] = `Bearer ${auth.accessParam()}`;
    }
    return axios.post(`${endpoint}`, body, {
        headers: headers
    })
}
