<template>
  <li :class="[headerLink, className, {active: isActive()}]">
    <a @click="resolveEvent">
      <span class="icon">
        <i :class="[iconName, color]"></i>
      </span>
      {{header}}
      <b-badge v-if="badge && !sidebarOpened" class="sup badge rounded-f" variant="danger" pill>{{badge}}</b-badge>
      <strong><span v-if="badge && sidebarOpened" class="pull-right mr-3" variant="warning">{{badge}}</span></strong>
    </a>
  </li>
</template>

<script>
  import {mapActions, mapState} from 'vuex';

  export default {
    name: 'NavLink',
    props: {
      headerLink: {default: 'headerLink'},
      badge: {type: Number, dafault: ''},
      header: {type: String, default: ''},
      iconName: {type: String, default: ''},
      className: {type: String, default: ''},
      method: {type: String},
      params: {type: Object, default: null},
      color: {type: String, default: ''},
      index: {type: String, default: ''}
    },
    data() {
      return {
        headerLinkWasClicked: true,
      };
    },
    methods: {
      ...mapActions('layout', ['toggleSidebar', 'changeGraphics', 'changeQrCode', 'changeReports']),
      ...mapActions('list_requests', ['loadRequests']),
      resolveEvent() {
        switch (this.method) {
          case 'toggleSidebar':
            this.toggleSidebar();
            break;
          case 'loadRequests':
            this.loadRequests(this.params);
            this.changeGraphics(false);
            this.changeReports(false);
            break;
          case 'changeGraphics':
            this.changeGraphics(true);
            break;
          case 'changeQrCode':
            this.changeGraphics(true);
            this.changeReports(false);
            break;
          case 'changeReports':
            this.changeGraphics(false);
            this.changeReports(true);
            break;
        }
      },
      isActive() {
        if (this.graphicsActive && this.index === 'Graficas'){
          return true
        } else if (this.reportsActive && this.index === 'Reportes'){
          return true
        } else if (this.params != null && !this.graphicsActive && !this.graphicsActive) {
          return this.params.status === this.activeStatus
        }
        return false;
      },
    },
    computed: {
      ...mapState('list_requests', {
        activeStatus: state => state.status
      }),
      ...mapState('layout', {
        sidebarOpened: state => !state.sidebarClose,
        graphicsActive: state => state.graphicsActive,
        reportsActive: state => state.reportsActive
      }),
    },
  };
</script>

<style src="./NavLink.scss" lang="scss" scoped/>
