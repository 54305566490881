// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'expose-loader?jQuery!jquery' // eslint-disable-line
import 'expose-loader?$!jquery' // eslint-disable-line
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueTouch from 'vue-touch';
import Trend from 'vuetrend';
import numFormat from 'vue-filter-number-format';
import infiniteScroll from 'vue-infinite-scroll';
import Tableau from 'vue-tableau'

import VueGoogleCharts from 'vue-google-charts'

import store from './store';
import router from './Routes';
import App from './App';
import Snotify from 'vue-snotify';


Vue.use(BootstrapVue);
Vue.use(VueTouch);
Vue.use(Trend);
Vue.use(infiniteScroll);
Vue.use(Snotify);
Vue.filter('number', numFormat);
Vue.use(Tableau);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA2TO0ZkJaZkl9UwwdiAVa3Xi7wZFJu9aM',
    language: 'es'
  },
});
Vue.use(VueGoogleCharts);

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App),
});
