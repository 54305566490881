<template>
  <div style="padding: 10px;">

    <b-card no-body>

      <b-form @submit="filterData">
        <label class="mt-3" for="dateFrom" style="margin: 5px 5px;">Filtro desde:</label>
        <input type="date" id="dateFrom" name="trip-start"
               v-model="dateFrom"
               min="2019-01-01" max="2024-12-31" required style="margin: 5px 5px;">

        <label class="mt-3" for="dateTo" style="margin: 5px 5px;">Filtro hasta:</label>
        <input type="date" id="dateTo" name="trip-start"
               v-model="dateTo"
               min="2019-01-01" max="2024-12-31" required style="margin: 5px 5px;">

        <b-button type="submit" variant="success" style="margin: 5px 5px;">Descargar</b-button>
        <b-button v-on:click="initGraphics" variant="danger" style="margin: 5px 5px;">Limpiar</b-button>
      </b-form>

    </b-card>


  </div>
</template>

<script>

import auth from "../../helpers/auth";

import {Api, constants, serializer} from "@/helpers";

require('jquery');

export default {
  name: 'Reports',
  data: () => {
    return {
      auth: auth,
      dateFrom: '',
      dateTo: ''
    }
  },
  components: {
  },
  mounted: function () {
  },

  methods: {
    triggerCalendar() {
      this.$refs.calendar.click();
    },
    filterData(evt) {
      evt.preventDefault();

      var dateFrom = new Date(this.dateFrom);
      var dateTo = new Date(this.dateTo);

      console.log(this.dateFrom, this.dateTo);

      if(this.dateFrom !== '' && this.dateTo !== '') {

        var diff = dateTo - dateFrom;
        //console.log('diferencia: ' + diff);

        if(diff >= 0) {
          new Promise(() => {

            this.downloadingReportCSV = true;
            this.$snotify.info('Generando reporte', 'Atención!!');

            Api.download(constants.endPoinds.exportAllCredits + serializer.get({
              status: 2,
              start_date: this.dateFrom,
              end_date: this.dateTo
            }),
            `report_${this.dateFrom + '_' + this.dateTo}.xlsx`,
            true, function downloaded() {
              this.downloadingReportCSV = false;
                  this.$snotify.clear();
            }.bind(this));

          });
        } else {
          this.$snotify.info('La fecha HASTA debe ser mayor', 'Atención !!');
        }


      } else {
        this.$snotify.info('La fechas deben estar llenas', 'Atención !!');
      }



    }
  }
};
</script>
<style src="./Reports.scss" lang="scss" scoped/>
